import {createReducer} from "redux-act";
import {IContactUsReduser} from "modules/types";
import {saveContactUs, saveContactUsFailed, saveContactUsSuccess} from "modules/actions";

const defaultState: IContactUsReduser = {
	is_data_requested: false,
	is_success: false,
	name: "",
	email: "",
	category: "",
	question: "",
};

export const contact_us = createReducer<IContactUsReduser>({}, defaultState)
	.on(saveContactUs, () => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(saveContactUsSuccess, (state: IContactUsReduser) => ({
		...state,
		is_success: true,
		is_data_requested: false,
	}))
	.on(saveContactUsFailed, () => ({
		...defaultState,
	}));
