import React, {useState} from "react";
import styled from "styled-components";
import {Modal} from "components/Modal";
import {partial} from "lodash";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
`;
const Text = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 26px;
`;

export const CheckBrowser: React.FC = () => {
	const [openState, changeOpenState] = useState(true);
	const isSafari = () => {
		const safari = navigator.userAgent.includes("Safari");
		const chrome = navigator.userAgent.includes("Chrome");
		return chrome && safari ? false : safari;
	};
	return isSafari() && openState ? (
		<Modal onClose={partial(changeOpenState, false)} width="55%">
			<Wrapper>
				<Text>{window.getTranslations("landing_modal_safari_desc")}</Text>
			</Wrapper>
		</Modal>
	) : null;
};
