import {put, call, select} from "redux-saga/effects";
import {Api, ApiError} from "modules/utils/Api";
import {
	fetchFAQSSuccess,
	fetchGuidelinesSuccess,
	fetchPrivacyPolicySuccess,
	fetchRoundSuccessAction,
	fetchRulesSuccess,
	fetchSquadsSuccessAction,
	fetchPrizesSuccess,
	fetchTeamLadderSuccess,
	helpRequested,
	fetchHelpFinished,
	fetchTeamStatisticsSuccess,
} from "modules/actions";
import {getJSONs} from "modules/selectors";
import {setRoundsFetchedStateAction} from "modules/actions/global";
import {IFetchTeamStatistics, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";

export const fetchRoundsSaga = function* () {
	try {
		const result = yield call(Api.JSON.rounds);
		ApiError.CHECK(result);
		yield put(setRoundsFetchedStateAction(true));
		yield put(fetchRoundSuccessAction(result));
	} catch (err) {
		console.log(err);
	}
};

export const fetchSquadsSaga = function* () {
	try {
		const result = yield call(Api.JSON.squads);
		ApiError.CHECK(result);
		yield put(fetchSquadsSuccessAction(result));
	} catch (err) {
		console.log(err);
	}
};

export const fetchPrizesJSON = function* () {
	const jsons = yield select(getJSONs);
	yield put(helpRequested());

	try {
		if (!jsons.prizes.length) {
			const prizes = yield call(Api.JSON.prizes);

			yield put(fetchPrizesSuccess({prizes: prizes}));
		}
		yield put(fetchHelpFinished());
	} catch (e) {
		console.log(e);
		yield put(fetchHelpFinished());
	}
};

export const fetchGuidelinesJSON = function* () {
	const jsons = yield select(getJSONs);
	yield put(helpRequested());

	try {
		if (!jsons.guidelines.length) {
			const guidelines = yield call(Api.JSON.guidelines);
			yield put(fetchGuidelinesSuccess({guidelines}));
		}
		yield put(fetchHelpFinished());
	} catch (e) {
		console.log(e);
		yield put(fetchHelpFinished());
	}
};

export const fetchRulesJSON = function* () {
	const jsons = yield select(getJSONs);
	yield put(helpRequested());

	try {
		if (!jsons.rules.length) {
			const rules = yield call(Api.JSON.rules);

			yield put(fetchRulesSuccess({rules}));
		}
		yield put(fetchHelpFinished());
	} catch (e) {
		console.log(e);
		yield put(fetchHelpFinished());
	}
};

export const fetchPrivacyPolicyJSON = function* () {
	const jsons = yield select(getJSONs);
	yield put(helpRequested());

	try {
		if (!jsons.privacy_policy.length) {
			const response = yield call(Api.JSON.privacy_policy);

			yield put(fetchPrivacyPolicySuccess({privacy_policy: response}));
		}
		yield put(fetchHelpFinished());
	} catch (e) {
		console.log(e);
		yield put(fetchHelpFinished());
	}
};

export const fetchFAQSJSON = function* () {
	const jsons = yield select(getJSONs);
	yield put(helpRequested());
	try {
		if (!jsons.faqs.length) {
			const faqs = yield call(Api.JSON.faq);

			yield put(fetchFAQSSuccess({faqs}));
		}
		yield put(fetchHelpFinished());
	} catch (e) {
		console.log(e);
		yield put(fetchHelpFinished());
	}
};

export const fetchTeamLadderSaga = function* () {
	try {
		const result = yield call(Api.JSON.ladder);
		ApiError.CHECK(result);
		yield put(fetchTeamLadderSuccess(result));
	} catch (err) {
		console.log(err);
	}
};

export const fetchTeamStatisticsSaga = function* (action: ISagaAction<IFetchTeamStatistics>): SagaIterator {
	try {
		const result = yield call(Api.JSON.team_statistics, action.payload);
		ApiError.CHECK(result);
		if (result.code) {
			yield put(
				fetchTeamStatisticsSuccess({
					home_squad_id: action.payload.home_id,
					away_squad_id: action.payload.away_id,
					league_ranks: {},
					season_record: {},
				})
			);
		} else {
			yield put(fetchTeamStatisticsSuccess(result));
		}
	} catch (err) {
		console.log(err);
	}
};
