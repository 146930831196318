import styled from "styled-components";
import theme from "assets/css/theme";
import {NavLink} from "react-router-dom";

interface IButtonAsLink {
	readonly as?: "a" | "div" | "span" | typeof NavLink;
	readonly href?: string;
}

interface IButtonInterface extends IButtonAsLink {
	readonly transparent?: boolean;
	readonly disabled?: boolean;
	readonly max_width?: string;
	readonly font_size?: string;
}

export const RegularButton = styled.button`
	color: #ffffff;
	font-family: ${theme.base_bold_font};
	font-size: ${({font_size}) => font_size || "14px"};
	letter-spacing: 0.5px;
	line-height: 16px;
	text-align: center;
	width: 100%;
	max-width: ${({max_width}: IButtonInterface) => max_width || "100%"};
	height: 40px;
	border: none;
	border-radius: 4px;
	background: ${({transparent}: IButtonInterface) => (transparent ? "transparent" : theme.primary)};
	opacity: ${({disabled}: IButtonInterface) => (disabled ? "0.3" : "1")};
	cursor: pointer;
	display: block;
	white-space: nowrap;
	text-transform: uppercase;

	@media (max-width: 820px) {
		max-width: 100%;
	}
`;
