import {createAction} from "redux-act";
import {IGigyaLoginParams, IGigyaUserData, IRegisterAction, IUpdateUserAction, IUser} from "modules/types";

export const gigyaLoginAction = createAction<IGigyaLoginParams>();
export const setIsNeedToRegisterAction = createAction<boolean>();
export const registerAction = createAction<IRegisterAction>();
export const authedAction = createAction<IUser>();
export const setGigyaDataAction = createAction<IGigyaUserData>();
export const getSessionAction = createAction();
export const getSessionSuccessAction = createAction();
export const updateUserAction = createAction<IUpdateUserAction>();

export const deactivateUserAction = createAction();

export const logoutAction = createAction();
export const cleanUpAuth = createAction();

export const backdoorAction = createAction<string>();

export const recoverUserAction = createAction();
