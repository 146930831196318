import theme from "assets/css/theme";
import {PagePreloader} from "components";
import {Redirect} from "react-router-dom";
import {bindAll} from "lodash";
import {clearLeaveLeague, leaveLeague, showLeague} from "modules/actions";
import {getJoinedCodes, getMyLeague, getUser, isLeagueDataRequested} from "modules/selectors";
import {IStore} from "modules/types";
import CreateLeagueForm from "pages/Leagues/createLeague/createLeagueForm";
import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {LeagueToggleButton} from "./leagueToggleButton";
import {LeagueWrapper} from "./leagueWrapper";
import {EnumLeagueClass} from "modules/constants/enums";

const AboutLeagueWrapper = styled(LeagueWrapper)`
	padding: 20px;
`;

const LeagueName = styled.div`
	color: #333;
	letter-spacing: -0.1px;
	line-height: 36px;
	font-size: 30px;
	font-family: "AllProDisaplayB-Medium", sans-serif;
	margin-bottom: 25px;
	text-transform: uppercase;
`;

const LeagueTitle = styled.h4`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LeagueDescription = styled.div`
	font-size: 14px;
	letter-spacing: -.1px;
	line-height: 16px;
	font-family: ${theme.base_font}
	color: #555;
	margin-bottom: 20px;
`;

const LeagueField = styled.div`
	margin-bottom: 15px;
`;

const LeagueFieldLabel = styled.div`
	color: #333;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
`;

const LeagueFieldValue = styled.div`
	color: #757575;
	font-family: ${theme.base_font};
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
`;

const Leave = styled(LeagueFieldValue)`
	text-decoration: underline;
	color: #ff0000;
	margin-top: 20px;
	cursor: pointer;
`;
const EditButton = styled(LeagueToggleButton)``;

interface IProps {
	league_created_id?: number;
	league_id?: number;
	shadow?: boolean;
	background?: string;
	onBlurBackground?: (prop: boolean) => void;
}

const mapStateToProps = (state: IStore) => ({
	league: getMyLeague(state),
	joined_codes: getJoinedCodes(state),
	is_data_requested: isLeagueDataRequested(state),
	user: getUser(state),
});

const mapDispatchToProps = {
	showLeague,
	leaveLeague,
	clearLeaveLeague,
};

type TProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & IProps;

interface IState {
	create_form_showing: boolean;
}

class LeagueAboutComponent extends React.Component<TProps, IState> {
	public state = {
		create_form_showing: false,
	};

	constructor(props: TProps) {
		super(props);
		bindAll(this, ["showLeagueForm", "closeLeagueForm", "handleLeave"]);
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount(): void {
		const {league_id, showLeague: show} = this.props;

		if (league_id) {
			show(league_id);
		}
	}

	public componentWillUnmount() {
		const {clearLeaveLeague: clear} = this.props;
		clear();
	}

	public handleLeave() {
		const {leaveLeague: leave, league} = this.props;

		leave({
			league_code: league.code,
			league_id: league.id,
		});
	}

	/**
	 * Fetch league by id
	 */
	public componentDidUpdate(prevProps: IProps) {
		const {showLeague: show, league_created_id} = this.props;

		if (league_created_id && league_created_id !== prevProps.league_created_id) {
			show(league_created_id);
		}
	}

	public showLeagueForm() {
		const {onBlurBackground} = this.props;

		this.setState({create_form_showing: true});

		if (onBlurBackground) {
			onBlurBackground(true);
		}
	}

	public closeLeagueForm() {
		const {onBlurBackground} = this.props;
		this.setState({create_form_showing: false});

		if (onBlurBackground) {
			onBlurBackground(false);
		}
	}

	public get update_league() {
		const {league} = this.props;

		return <CreateLeagueForm league={league} closeForm={this.closeLeagueForm} />;
	}

	public get leave_button() {
		const {league, user} = this.props;
		return (
			league.user_id !== user.id &&
			league.class === EnumLeagueClass.Regular && (
				<Leave onClick={this.handleLeave}>{window.getTranslations("pickem_alert_save_button_leave")}</Leave>
			)
		);
	}

	public get about_league() {
		const {league, league_created_id, shadow, background, user, joined_codes} = this.props;
		const is_commissioner = league.user_id === user.id;
		const isRedirect = () => {
			return joined_codes && joined_codes[league.code] && !joined_codes[league.code].is_joined;
		};
		if (isRedirect()) {
			return <Redirect to={"/leagues"} />;
		}

		return (
			<AboutLeagueWrapper shadow={shadow} background={background}>
				<LeagueTitle>
					{league_created_id
						? window.getTranslations("leaguecreate_form_text_congratulations")
						: is_commissioner
						? window.getTranslations("leaguesettings_form_header_league_settings")
						: window.getTranslations("aboutcompetition_title_th")}
					{is_commissioner ? <EditButton onClick={this.showLeagueForm}>Edit</EditButton> : null}
				</LeagueTitle>

				<LeagueName>{league.name}</LeagueName>
				<LeagueDescription>{league.description}</LeagueDescription>
				<LeagueField>
					<LeagueFieldLabel>
						{window.getTranslations("leagueinvite_dropdown_starting_Round")}
					</LeagueFieldLabel>
					<LeagueFieldValue>
						{window.getTranslations(`game_week_label_${league.start_round}`)}
					</LeagueFieldValue>
				</LeagueField>
				<LeagueField>
					<LeagueFieldLabel>{window.getTranslations("fld_privacy")}</LeagueFieldLabel>
					<LeagueFieldValue>
						{league.privacy === "public"
							? window.getTranslations("competitioncreate_form_toggle_public")
							: window.getTranslations("competitioncreate_form_toggle_private")}
					</LeagueFieldValue>
				</LeagueField>

				{this.leave_button}
			</AboutLeagueWrapper>
		);
	}

	/**
	 * @ignore
	 */
	public render() {
		const {create_form_showing} = this.state;
		const {is_data_requested} = this.props;

		if (is_data_requested) {
			return <PagePreloader />;
		}

		return !create_form_showing ? this.about_league : this.update_league;
	}
}

export const LeagueAbout = connect(mapStateToProps, mapDispatchToProps)(LeagueAboutComponent);
