import {createReducer, Reducer} from "redux-act";
import {ITeamStatistics} from "modules/types";
import {clearTeamStatistics, fetchTeamStatisticsSuccess} from "modules/actions/jsons";

const initialState: {[key: string]: ITeamStatistics} = {};

export const team_statistics: Reducer<{[key: string]: ITeamStatistics}> = createReducer({}, initialState);
team_statistics
	.on(fetchTeamStatisticsSuccess, (state, payload) => ({
		...state,
		[`${payload.home_squad_id}_${payload.away_squad_id}`]: payload,
	}))
	.on(clearTeamStatistics, () => ({
		/* Clear */
	}));
