import {IJoinLeague, ILeague, IMyLeague, IRound, IStore} from "modules/types";
import {get, isEmpty} from "lodash";
import {createSelector} from "reselect";
import {EnumRoundStatusType} from "modules/constants/enums";

export const getMyLeagues = ({my_leagues}: IStore): ILeague[] => get(my_leagues, "items", []);

export const isLeaguesDataRequested = ({my_leagues}: IStore): boolean => get(my_leagues, "is_data_requested", false);
export const getJoinLeagues = ({join_leagues}: IStore): IJoinLeague[] => get(join_leagues, "items", []);

export const isJoinLeaguesDataRequested = ({join_leagues}: IStore): boolean =>
	get(join_leagues, "is_data_requested", false);

export const hasNextLoadMore = ({join_leagues}: IStore): boolean => get(join_leagues, "next", false);

export const getJoinedCodes = ({join_leagues}: IStore) => join_leagues.joined_codes;
export const getMyLeague = ({league_manage}: IStore) => league_manage.league;
export const isLeagueDataRequested = ({league_manage}: IStore): boolean =>
	get(league_manage, "is_data_requested", false);

export const getLeagueLadder = ({league}: IStore) => get(league, "ladder");
export const getIsLeagueLadderNext = ({league}: IStore) => get(league, "next", false);

const getRounds = ({rounds}: IStore) => rounds;
export const getLadderRounds = createSelector(getMyLeague, getRounds, (league: IMyLeague, rounds: IRound[]) => {
	if (isEmpty(league) || isEmpty(rounds)) {
		return [];
	}

	const {start_round} = league;

	return (
		rounds.filter(({id, status}) => {
			return id >= start_round && status !== EnumRoundStatusType.SCHEDULED;
		}) || rounds.filter(({id}) => id === start_round)
	);
});
