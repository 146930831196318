import React from "react";
import styled from "styled-components";
import checked from "assets/img/icons/checked_form.svg";

const Checkbox = styled.input`
	display: none;
`;

const Label = styled.label`
	transition: 0.4s;
	display: inline-block;
	position: relative;
	font-weight: 500;
	color: #434343;
	vertical-align: baseline;
	text-align: left;
	box-sizing: border-box;
	font-size: 11px;
	margin-right: 10px;

	&:before {
		display: block;
		content: "";
		border: 1px solid #1b48e0;
		box-sizing: border-box;
		border-radius: 2px;
		width: 16px;
		height: 16px;

		${Checkbox}:checked + & {
			background-image: url(${checked});
			background-size: 1em;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	a {
		color: #434343;
	}
`;

const onChangePlaceholder = () => null;

interface IProps {
	name: string;
	is_checked?: boolean;
	onChange?: () => void;
}

export const CheckboxStyled = ({name, is_checked, onChange, ...rest}: IProps) => (
	<React.Fragment>
		<Checkbox
			type="checkbox"
			name={name}
			id={name}
			checked={is_checked}
			onChange={onChange || onChangePlaceholder}
			{...rest}
		/>
		<Label htmlFor={name} />
	</React.Fragment>
);
