import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/logo-tall.svg";
import {useI18n} from "modules/hooks";
import theme from "assets/css/theme";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isComingSoonSelector, getIsNeedToRegisterSelector} from "modules/selectors";
import deLogo from "assets/img/de-logo-tall.svg";
import {RegularButton} from "components/Buttons";
import {GameSettingsUtils} from "modules/utils/secret";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
	gap: 20px;
	a {
		max-width: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const Logo = styled.img`
	width: 100%;
	max-width: 175px;
`;
const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
	text-transform: uppercase;
`;

const Text = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 26px;
	max-width: 500px;
`;
const WrapperComingSoon = styled.div`
	.modal-wrapper {
		z-index: 10 !important;
	}
`;

export const ComingSoonModal: React.FC = () => {
	const isComingSoon = useSelector(isComingSoonSelector);
	const isRegister = useSelector(getIsNeedToRegisterSelector);
	const {pathname} = useLocation();
	const closeClickPlaceholder = () => null;
	const titleText = useI18n("comming_soon_title_key");
	const text = useI18n("comming_soon_text_key");
	const isComingSoonPage = pathname === "/game";
	const isShowComingSoon = isComingSoonPage && isComingSoon && !isRegister;
	const headerLogo = process.env.REACT_APP_NAME === "nfl-de" ? deLogo : logo;

	return isShowComingSoon ? (
		<WrapperComingSoon>
			<Modal onClose={closeClickPlaceholder} isCloseable={false} width="750px">
				<Wrapper>
					<Logo src={headerLogo} alt={"NFL logo"} />
					<Title>{titleText}</Title>
					<Text>{text}</Text>

					<RegularButton as={"a"} href={GameSettingsUtils.linkToPTTP}>
						{window.getTranslations("coming_soon_pttp_button", "Play Now")}
					</RegularButton>
				</Wrapper>
			</Modal>
		</WrapperComingSoon>
	) : null;
};
