import React from "react";
import styled from "styled-components";

const mapDirectionToDegrees = {
	top: 0,
	right: 270,
	bottom: 180,
	left: 90,
};

type Direction = "top" | "bottom" | "left" | "right";

interface IPropSide {
	to: Direction;
}

interface IProps {
	width?: number;
	height?: number;
	color?: string;
	to?: Direction;
}

const SvgContainer = styled.svg`
	transform: rotate(${({to}: IPropSide) => mapDirectionToDegrees[to]}deg);
`;

export const IconArrowSlim: React.FC<IProps> = ({width = 20, height = 12, to = "top", color = "#9E9E9E", ...rest}) => (
	<SvgContainer to={to} width={`${width}px`} height={`${height}px`} viewBox={`0 0 20 12`} {...rest}>
		<title>Arrow Slim</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-936.000000, -32.000000)" fill="currentColor">
				<g transform="translate(936.000000, 32.000000)">
					<g transform="translate(10.000000, 6.000000) rotate(-180.000000) translate(-10.000000, -6.000000)">
						<path
							d="M10.0212766,0.0425531915 L10,0.0425531915 L0.276595745,9.78723404 C0.0212765957,
							10.0425532 0.0212765957,10.4468085 0.276595745,10.6808511 L1.27659574,
							11.6808511 C1.53191489,11.9361702 1.93617021,11.9361702 2.17021277,11.6808511 L10,
							3.85106383 L10.0212766,3.85106383 L17.8510638,11.6808511 C18.106383,11.9361702 18.5106383,
							11.9361702 18.7446809,11.6808511 L19.7446809,10.6808511 C20,
							10.4255319 20,10.0212766 19.7446809,9.78723404 L10.0212766,0.0425531915 Z"
							fillRule="nonzero"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgContainer>
);

IconArrowSlim.defaultProps = {
	width: 20,
	height: 12,
	color: "#9E9E9E",
	to: "top",
};
