import {SagaIterator} from "redux-saga";
import {all, takeLatest} from "redux-saga/effects";
import {
	fetchRoundsAction,
	fetchSquadsAction,
	fetchTranslationsAction,
	backdoorAction,
	gigyaLoginAction,
	createLeague,
	fetchLadder,
	fetchMoreLadder,
	inviteViaEmail,
	joinToLeague,
	leaveLeague,
	searchAndJoin,
	showLeague,
	showLeaguesForJoin,
	showMyLeagues,
	updateLeague,
	fetchFAQS,
	fetchPrizes,
	fetchRules,
	fetchPrivacyPolicy,
	fetchGuidelines,
	saveContactUs,
	registerAction,
	logoutAction,
	makePickAction,
	getSessionAction,
	updateUserAction,
	deactivateUserAction,
	fetchCountryAction,
	savePredictionsListAction,
	fetchPredictionsAction,
	errorsGlobalError,
	fetchTeamLadderAction,
	recoverUserAction,
	rePickAction,
	fetchTeamStatistics,
	subscribeToLiveScores,
	fetchSharedPredictionsAction,
	fetchSharingImgUrl,
	sendIncrementQuery,
	authedAction,
} from "modules/actions";
import {
	backdoorSaga,
	gigyaLoginSaga,
	fetchRoundsSaga,
	fetchSquadsSaga,
	createLeagueSaga,
	fetchLadderSaga,
	fetchMoreLadderSaga,
	updateLeagueSaga,
	fetchTranslationsSaga,
	getLeagueByIdSaga,
	joinToLeagueSaga,
	postInvitesSendViaEmail,
	postLeagueLeaveSaga,
	searchAndJoinSaga,
	showMyLeaguesForJoinSaga,
	fetchPrizesJSON,
	showMyLeaguesSaga,
	fetchPrivacyPolicyJSON,
	fetchRulesJSON,
	fetchGuidelinesJSON,
	fetchFAQSJSON,
	registerSaga,
	logoutSaga,
	movePickSaga,
	getSessionSaga,
	updateAccountSaga,
	deactivateUserSaga,
	savePredictionsListSaga,
	fetchPredictionsSaga,
	errorsManagerSaga,
	fetchTeamLadderSaga,
	recoverUserSaga,
	rePickSaga,
	fetchTeamStatisticsSaga,
	ChecksumsToSaga,
	fetchSharedPredictionsSaga,
	fetchSharingImgUrlSaga,
	sendIncrementSaga,
} from "modules/sagas";
import {postContactUsSaga} from "modules/sagas/contact_us";
import {fetchCountrySaga} from "modules/sagas/global";
import {checkPredictionsForSave} from "./predictions";

/**
 * Root saga that connect sagas with actions.
 */
export const globalSaga = function* (): SagaIterator {
	// @ts-ignore
	yield all([
		yield takeLatest(errorsGlobalError, errorsManagerSaga),
		// JSON
		takeLatest(fetchRoundsAction, fetchRoundsSaga),
		takeLatest(fetchSquadsAction, fetchSquadsSaga),
		takeLatest(fetchPrizes, fetchPrizesJSON),
		takeLatest(fetchGuidelines, fetchGuidelinesJSON),
		takeLatest(fetchRules, fetchRulesJSON),
		takeLatest(fetchFAQS, fetchFAQSJSON),
		takeLatest(fetchPrivacyPolicy, fetchPrivacyPolicyJSON),
		takeLatest(fetchTeamLadderAction, fetchTeamLadderSaga),
		takeLatest(subscribeToLiveScores, ChecksumsToSaga),
		// Auth
		takeLatest(gigyaLoginAction, gigyaLoginSaga),
		takeLatest(backdoorAction, backdoorSaga),
		takeLatest(registerAction, registerSaga),
		takeLatest(logoutAction, logoutSaga),
		takeLatest(getSessionAction, getSessionSaga),
		takeLatest(updateUserAction, updateAccountSaga),
		takeLatest(deactivateUserAction, deactivateUserSaga),
		takeLatest(recoverUserAction, recoverUserSaga),
		// Global
		takeLatest(fetchTranslationsAction, fetchTranslationsSaga),
		takeLatest(fetchCountryAction, fetchCountrySaga),
		// Leagues
		takeLatest(createLeague, createLeagueSaga),
		takeLatest(updateLeague, updateLeagueSaga),
		takeLatest(showMyLeagues, showMyLeaguesSaga),
		takeLatest(showLeaguesForJoin, showMyLeaguesForJoinSaga),
		takeLatest(joinToLeague, joinToLeagueSaga),
		takeLatest(leaveLeague, postLeagueLeaveSaga),
		takeLatest(showLeague, getLeagueByIdSaga),
		takeLatest(inviteViaEmail, postInvitesSendViaEmail),
		takeLatest(searchAndJoin, searchAndJoinSaga),
		takeLatest(fetchLadder, fetchLadderSaga),
		takeLatest(fetchMoreLadder, fetchMoreLadderSaga),
		// Contact us
		takeLatest(saveContactUs, postContactUsSaga),
		// Predictions
		takeLatest(makePickAction, movePickSaga),
		takeLatest(savePredictionsListAction, savePredictionsListSaga),
		takeLatest(fetchPredictionsAction, fetchPredictionsSaga),
		takeLatest(fetchSharedPredictionsAction, fetchSharedPredictionsSaga),
		takeLatest(rePickAction, rePickSaga),
		takeLatest(authedAction, checkPredictionsForSave),
		// Team Statistics
		takeLatest(fetchTeamStatistics, fetchTeamStatisticsSaga),
		// Sharing
		takeLatest(fetchSharingImgUrl, fetchSharingImgUrlSaga),
		takeLatest(sendIncrementQuery, sendIncrementSaga),
	]);
};

export * from "./auth";
export * from "./leagues";
export * from "./league";
export * from "./translations";
export * from "./jsons";
export * from "./brackets";
export * from "./predictions";
export * from "./error_managment";
export * from "./live_score";
export * from "./share";
