import React from "react";
import {Modal} from "components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getHighlightSelector} from "modules/selectors";
import {setHighlightVideoAction} from "modules/actions";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 240px;
	video {
		width: 101%;
		height: 100%;
	}
`;

export const VideoPlayerModal = () => {
	const dispatch = useDispatch();
	const highlight: string = useSelector(getHighlightSelector);
	const closeClick = () => {
		dispatch(setHighlightVideoAction(""));
	};
	const videoUrl = `${String(process.env.REACT_APP_VIDEO_URL)}${highlight}#t=0.1`;
	return highlight ? (
		<Modal onClose={closeClick} isCloseable={true} isCloseOutside={true} width="55%">
			<Wrapper>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
				<video controls={true} preload="true" autoPlay={true}>
					<source src={videoUrl} type="video/mp4" />
				</video>
			</Wrapper>
		</Modal>
	) : null;
};
