import {createReducer} from "redux-act";
import {IInvitesReducer} from "modules/types";
import {inviteViaEmail, inviteViaEmailFailed, inviteViaEmailSuccess, inviteViaEmailClear} from "modules/actions";

const defaultState = {
	is_data_requested: false,
	is_success: false,
};

export const league_invites = createReducer<IInvitesReducer>({}, defaultState)
	.on(inviteViaEmail, () => ({
		...defaultState,
		is_data_requested: true,
	}))
	.on(inviteViaEmailSuccess, () => ({
		is_data_requested: false,
		is_success: true,
	}))
	.on(inviteViaEmailFailed, () => defaultState)
	.on(inviteViaEmailClear, () => defaultState);
