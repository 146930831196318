import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import theme from "assets/css/theme";
import {media} from "assets/css/media";
import mxBackground from "assets/img/mx/background.jpg";

export const FooterWrapper = styled.div<{isMX?: boolean}>`
	background-color: ${theme.footer_bg};
	color: ${theme.menu_color};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 0;

	${(props) =>
		props.isMX &&
		css`
			background: url(${mxBackground});
		`}
`;

export const FooterMenuWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const TermStyles = css`
	position: relative;
	font-size: 14px;
	margin: 20px 0;
	color: #555555;
	padding-bottom: 10px;
	line-height: 22px;
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		border-bottom: 1px solid #555555;
		left: 0;
		bottom: 1px;
	}
`;
export const TermLink = styled(Link)`
	${TermStyles};
`;

export const Copywriting = styled.div`
	font-size: 12px;
	line-height: 22px;
	max-width: 900px;
	text-align: center;
	color: ${theme.footer_text_color};
`;

export const FooterImgWrapDiv = styled.div`
	display: flex;
	flex-direction: row;
`;

export const FooterImgDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0 20px;
`;

export const FooterImg = styled.img`
	max-width: 50px;
`;

export const FooterTermsDiv = styled.div`
	display: flex;
	@media (max-width: ${media.desktop}) {
		flex-direction: column;
	}
`;
