// eslint-disable-next-line import/no-anonymous-default-export
export default {
	primary: "#1B48E0",
	secondary: "#13612D",
	red: "#FF0000",
	body_bg: "#F3F6F7",
	content_width: "1240px",
	base_text_color: "#343232",
	base_font: "All-ProSans, sans-serif",
	base_bold_font: "All-ProSans-Bold, sans-serif",
	secondary_bold_font: "AllProDisaplayB-Bold, sans-serif",
	mediumFont: "All-ProSans-Medium, sans-serif",
	mediumSecondFont: "AllProDisaplayB-Medium, sans-serif",
	semiBoldFont: "All-ProSans-Semibold, sans-serif",
	buttons_color: "#1976D2",
	menu_color: "#cacaca",
	sub_menu_color: "#555555",
	menu_font: "EndzoneSans-Bold, sans-serif",
	rankings_header_bg: "#0B294E",
	header_bg: "#007DC3",
	presented_color: "#555555",
	ladder_bg_item: "#343232",
	headings_bg: "#393E3F",
	footer_bg: "#151515",
	footer_text_color: "#FAFAFA",
	titles: "#333333",
	descriptions: "#757575",
	placeholder: "#757575",
	primary_hover: "#143CAB",
};
