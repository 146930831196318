import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {isAuthedSelector} from "modules/selectors";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const isAuthed = useSelector(isAuthedSelector);

	return isAuthed ? (
		<Route {...props} />
	) : (
		<Redirect
			to={{
				pathname: "/",
				state: {from: props.location},
			}}
		/>
	);
};
