import {
	clearLeaguesState,
	showLeague,
	showLeagueFailed,
	showLeagueSuccess,
	updateLeague,
	updateLeagueFailed,
	updateLeagueSuccess,
} from "modules/actions";
import {EnumLeagueClass, EnumLeaguePrivacy, EnumLeagueStatus} from "modules/constants/enums";
import {ILeagueManageReducer, IMyLeague} from "modules/types";
import {createReducer} from "redux-act";

const defaultState: ILeagueManageReducer = {
	is_data_requested: false,
	league: {
		id: 0,
		name: "",
		status: EnumLeagueStatus.Scheduled,
		class: EnumLeagueClass.Unknown,
		class_id: 0,
		rank: 0,
		avg_score: 0,
		avg_margin: 0,
		score: 0,
		avg_last_round_score: 0,
		avg_last_round_margin: 0,
		last_round_score: 0,
		start_round: 0,
		privacy: EnumLeaguePrivacy.Public,
		code: "",
		num_teams: 0,
		coaches_box: 0,
		avatar_version: 0,
		regenerated_from: 0,
		description: "",
		start_at: new Date(),
		total: 0,
		user_id: 0,
	},
	is_error: false,
};

export const league_manage = createReducer<ILeagueManageReducer>({}, defaultState)
	.on(showLeague, (state: ILeagueManageReducer) => ({
		...state,
		is_data_requested: true,
	}))
	.on(showLeagueSuccess, (state: ILeagueManageReducer, payload: IMyLeague) => ({
		...state,
		league: payload,
		is_data_requested: false,
		is_error: false,
	}))
	.on(showLeagueFailed, (state: ILeagueManageReducer) => ({
		...state,
		is_error: true,
		is_data_requested: false,
	}))
	.on(updateLeague, (state: ILeagueManageReducer) => ({
		...state,
		is_data_requested: true,
	}))
	.on(updateLeagueSuccess, (state: ILeagueManageReducer, payload: IMyLeague) => ({
		...state,
		league: payload,
		is_data_requested: false,
	}))
	.on(updateLeagueFailed, (state: ILeagueManageReducer) => ({
		...state,
		is_data_requested: false,
	}))
	.on(clearLeaguesState, (state: ILeagueManageReducer) => ({
		...defaultState,
	}));
