export enum MenuItemType {
	External = "external",
	SignUp = "signup",
	Link = "link",
}

export interface IMenuItem {
	label: string;
	link: string;
	mobileLink?: string;
	isAuthed: boolean;
	menuItemType: MenuItemType;
	onClick?: () => void;
	subItems?: IMenuItem[];
}
