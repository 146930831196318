import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/logo-tall.svg";
import {RegularButton} from "components/Buttons";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {getOverrideModalState} from "modules/selectors";
import {hideConfirmSaveStoredPredictions, answerConfirmSaveStoredPredictions} from "modules/actions";
import deLogo from "assets/img/de-logo-tall.svg";
import {ConfirmModal} from "modules/constants/enums";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
	gap: 16px;
	button {
		max-width: 250px;
	}
`;

const Logo = styled.img`
	width: 100%;
	max-width: 175px;
`;

const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
`;

const Text = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 26px;
`;

export const ConfirmSaveModal: React.FC = () => {
	const dispatch = useDispatch();
	const modalState = useSelector(getOverrideModalState);
	const closeClick = () => {
		dispatch(answerConfirmSaveStoredPredictions(ConfirmModal.Decline));
		dispatch(hideConfirmSaveStoredPredictions());
	};

	const confirm = () => {
		dispatch(answerConfirmSaveStoredPredictions(ConfirmModal.Confirm));
		dispatch(hideConfirmSaveStoredPredictions());
	};

	const logoIcon = process.env.REACT_APP_NAME === "nfl-de" ? deLogo : logo;
	return modalState ? (
		<Modal onClose={closeClick} isCloseable={true} isCloseOutside={true} width="55%">
			<Wrapper>
				<Logo src={logoIcon} alt={"NFL logo"} />
				<Title>{window.getTranslations("modal_header_are_you_sure")}</Title>
				<Text>{window.getTranslations("modal_body_override_predictions")}</Text>
				<RegularButton onClick={closeClick}>
					{window.getTranslations("modal_body_override_predictions_decline")}
				</RegularButton>
				<RegularButton onClick={confirm}>
					{window.getTranslations("modal_body_override_predictions_confirm")}
				</RegularButton>
			</Wrapper>
		</Modal>
	) : null;
};
