import React, {useCallback, useState} from "react";
import styled from "styled-components";
import logo from "assets/img/NFLShield.svg";
import {useI18n} from "modules/hooks";
import theme from "assets/css/theme";
import {CheckboxStyled} from "components/Form";
import {RegularButton} from "components/Buttons";
import {REGISTRATION_FORM_ERRORS, REGISTRATION_FORM_STATE} from "modules/constants";
import {validateField} from "modules/utils/helpers";
import {chain, noop} from "lodash";
import {IDictionary} from "modules/types";
import {useDispatch, useSelector} from "react-redux";
import {recoverUserAction} from "modules/actions/auth";
import {Modal} from "components/Modal";
import {getUser} from "modules/selectors";
import deLogo from "assets/img/de-logo-tall.svg";

const FormWrapper = styled.div`
	padding: 0 20px 50px;
	background: #ffffff;
	display: flex;
	align-items: center;
	flex-direction: column;
`;
const Form = styled.form``;
const Logo = styled.img`
	width: 120px;
`;

const Title = styled.div`
	margin: 20px 0;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	font-family: ${theme.base_bold_font};
`;
const FormLine = styled.div`
	margin-bottom: 20px;
`;
const CheckBox = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	color: #555555;
	line-height: 1.5;
`;
const ErrorText = styled.div`
	padding-top: 5px;
	font-size: 12px;
	color: #d00b0b;
`;

export const RecoverModal = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const [formValues, setFormValues] = useState(REGISTRATION_FORM_STATE);
	const [formErrors, setFormErrors] = useState(REGISTRATION_FORM_ERRORS);
	const titleTranslation = useI18n("register_returninguser_returning_heading");
	const termsText = useI18n("register_form_label_terms");

	const clearError = (name: string) => {
		setFormErrors({
			...formErrors,
			[name]: "",
		});
	};
	const formChangeHandler = (e: React.FormEvent<HTMLFormElement>) => {
		const {name, value, checked, type} = e.target as HTMLFormElement;
		const formValue = type === "checkbox" ? Number(checked) : value;
		setFormValues({
			...formValues,
			[name]: formValue,
		});
		if (type !== "checkbox") {
			clearError(name);
			// eslint-disable-next-line sonarjs/no-duplicated-branches
		} else if (formValue) {
			clearError(name);
		}
	};
	const validateHandle = useCallback(
		(e: React.BaseSyntheticEvent) => {
			const error = validateField(e.target, formValues);
			if (error) {
				setFormErrors({
					...formErrors,
					[error.name]: error.error,
				});
			}
		},
		[formErrors, formValues]
	);
	const submitFormHandler = (e: React.FormEvent) => {
		e.preventDefault();
		dispatch(recoverUserAction());
	};
	const isValidState = (data: IDictionary<unknown>) => Boolean(chain(data).values().compact().value().length);
	const isButtonsDisabled = isValidState(formErrors) || !formValues.terms;
	const headerLogo = process.env.REACT_APP_NAME === "nfl-de" ? deLogo : logo;
	const trackFtag = () => {
		if (window.ftag) {
			window.ftag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflsb0/2023n0+standard",
			});
		}
	};

	if (user.recovered || !user.id) {
		return null;
	}

	return (
		<Modal onClose={noop} isCloseable={false} width={"340px"} height={"95%"}>
			<FormWrapper>
				<Logo src={headerLogo} alt={"NFL logo"} />
				<Title>{titleTranslation}</Title>
				<Form onChange={formChangeHandler} onSubmit={submitFormHandler}>
					<FormLine>
						<CheckBox>
							<div>
								<CheckboxStyled
									// @ts-ignore
									onChange={validateHandle}
									name={"terms"}
									is_checked={Boolean(formValues.terms)}
									data-validations="checkedOnly"
								/>
							</div>
							{/* Reason: This is value from our Backend and we trust this data */}
							{/* eslint-disable-next-line react/no-danger */}
							<div dangerouslySetInnerHTML={{__html: termsText}} />
						</CheckBox>
						{formErrors.terms ? <ErrorText>{formErrors.terms}</ErrorText> : null}
					</FormLine>
					<RegularButton
						disabled={isButtonsDisabled}
						onClick={trackFtag}
						data-food_track_id={"/nflsb0/nflsb003+standard"}>
						{window.getTranslations("register_form_button_continue")}
					</RegularButton>
				</Form>
			</FormWrapper>
		</Modal>
	);
};
