import {Clipboard} from "ts-clipboard";

const matchMedia = window.matchMedia;

export const isDesktop = () => typeof matchMedia === "undefined" || matchMedia("(min-width: 1200px)").matches;

export const isMobile = () => typeof matchMedia === "undefined" || matchMedia("(max-width: 820px)").matches;

export const copyToClipboard = (text: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		try {
			Clipboard.copy(text);
			resolve({});
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});
};

export const delay = (ms: number): Promise<NodeJS.Timeout> =>
	// Legacy code
	// eslint-disable-next-line @typescript-eslint/no-implied-eval
	new Promise((res: any) => setTimeout(res, ms));

export * from "./Share";
export * from "./OmnitureTracking";
export * from "./analytic";
