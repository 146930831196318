import {createReducer} from "redux-act";
import {IDraftLeagueReducer, ILeagueLadderResponse} from "modules/types";
import {clearLeagueFlags, createLeagueSuccess, fetchLadderSuccess} from "modules/actions";

const initialState: IDraftLeagueReducer = {
	league_created_id: null,
	ladder: [],
	next: false,
};

export const league = createReducer({}, initialState)
	.on(createLeagueSuccess, (state, league_id) => ({
		...state,
		league_created_id: league_id,
	}))
	.on(clearLeagueFlags, (state) => ({
		...state,
		league_created_id: null,
	}))
	.on(fetchLadderSuccess, (state, payload: ILeagueLadderResponse) => ({
		...state,
		ladder: payload.ladder,
		next: payload.next,
	}));
