import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	max-width: 1240px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 100%;
	position: relative;
`;
