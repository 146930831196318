import {createAction} from "redux-act";

export interface IFetchSharingImgUrl {
	orientation: "desktop" | "mobile";
	country: string;
}

export const fetchSharingImgUrl = createAction<IFetchSharingImgUrl>();
export const fetchSharingImgUrlSuccess = createAction<string>();

export interface ISendIncrement {
	social: "fb" | "tw" | "mobile" | "bracket_generate";
	type: "league" | "team" | "predictor";
}

export const sendIncrementQuery = createAction<ISendIncrement>();
