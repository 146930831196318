import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import theme from "assets/css/theme";
import {hideBRSponsorModal} from "modules/actions";
import {getBRSponsorModalStatus} from "modules/selectors";
import FundoBrancoLogo from "assets/img/FundoBrancoLogo.png";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
	button {
		max-width: 250px;
	}
`;
const Logo = styled.img`
	width: 100%;
	max-width: 230px;
	margin-bottom: 15px;
`;
const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 15px;
`;
const Text = styled.div`
	font-size: 15px;
	text-align: center;
	line-height: 24px;
	margin-bottom: 10px;

	strong {
		font-family: ${theme.base_bold_font};
	}
`;

const LinkToShop = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-family: ${theme.base_bold_font};
	font-size: 14px;
	letter-spacing: 0.5px;
	line-height: 16px;
	text-align: center;
	width: 100%;
	height: 40px;
	border: none;
	border-radius: 4px;
	background: ${theme.primary};
	cursor: pointer;
	white-space: nowrap;
	text-transform: uppercase;
	max-width: 200px;

	@media (max-width: 820px) {
		max-width: 100%;
	}
`;

export const BRSponsorModal: React.FC = () => {
	const dispatch = useDispatch();
	const isOpenModal = useSelector(getBRSponsorModalStatus);

	const closeClick = () => {
		dispatch(hideBRSponsorModal());
	};

	return isOpenModal ? (
		<Modal onClose={closeClick} isCloseable={true} isCloseOutside={true} width="55%">
			<Wrapper>
				<Logo src={FundoBrancoLogo} alt={"NFL logo"} />
				<Title>Parabéns! </Title>
				<Text>Você liberou um desconto EXCLUSIVO na Sport America, a maior fan shop de NFL do Brasil.</Text>
				<Text>
					Clique AGORA no link, use o cupom <strong>SPORTAMERICASB20</strong> e compre produtos oficiais do
					seu time favorito
				</Text>
				<LinkToShop href={"https://www.sportamerica.com.br/NFL"} target={"_blank"}>
					shop now
				</LinkToShop>
			</Wrapper>
		</Modal>
	) : null;
};
