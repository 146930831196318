import React, {useState} from "react";
import {IMenuItem, MenuItemType} from "modules/types";
import {useSelector} from "react-redux";
import {isAuthedSelector, getGigyaDataSelector} from "modules/selectors";
import chevronIcon from "assets/img/icons/chevron.svg";
import {partial, get} from "lodash";
import externalIcon from "assets/img/icons/external.png";
import {WrapperMobile, DropdownItemMobile, NavItemMobile} from "components/Navigation/items/MenuItemMobile";
import {SignUp} from "components/Navigation/items/SignUp";

interface IProps {
	item: IMenuItem;
	isSub?: boolean;
}

export const MobileItem: React.FC<IProps> = ({item, isSub}) => {
	const isAuthed = useSelector(isAuthedSelector);
	const [isOpen, setIsOpen] = useState(false);
	const isHideItem = (targetItem: IMenuItem) => !isAuthed && targetItem.isAuthed;
	const mobileLink = get(item, "mobileLink", item.link);
	const userData = useSelector(getGigyaDataSelector);

	if (item.menuItemType === MenuItemType.SignUp) {
		if (userData?.gigya_id) {
			return null;
		}
		return <SignUp item={item} isMobile={true} />;
	}

	return (
		<React.Fragment>
			<WrapperMobile isHideItem={isHideItem(item)} isSub={isSub}>
				{
					{
						[MenuItemType.Link]: item.subItems ? (
							<React.Fragment>
								<DropdownItemMobile isOpen={isOpen} onClick={partial(setIsOpen, !isOpen)}>
									{window.getTranslations(item.label)}
									<img src={chevronIcon} alt="Chevron Icon" />
								</DropdownItemMobile>
							</React.Fragment>
						) : (
							<NavItemMobile to={mobileLink}>{window.getTranslations(item.label)}</NavItemMobile>
						),
						[MenuItemType.External]: (
							<NavItemMobile as={"a"} href={item.link} target={"_blank"} rel="noopener noreferrer">
								{window.getTranslations(item.label)}
								<img src={externalIcon} alt={"External Icon"} />
							</NavItemMobile>
						),
						[MenuItemType.SignUp]: null,
					}[item.menuItemType]
				}
			</WrapperMobile>
			{item.subItems &&
				isOpen &&
				item.subItems.map((subItem) => <MobileItem item={subItem} key={subItem.label} isSub={true} />)}
		</React.Fragment>
	);
};
