import React from "react";
import {generatePath, Link, LinkProps, NavLink} from "react-router-dom";

export const LeagueCreateLink = ({children}: {children: React.ReactElement | string}) => (
	<Link to={generatePath("/leagues/create")}>{children}</Link>
);

export const LeagueJoinLink = ({children}: {children: React.ReactElement | string}) => (
	<Link to={generatePath("/leagues/join")}>{children}</Link>
);

export const LeagueSettingsLink = (id: number) => ({to, ...props}: LinkProps) => (
	<NavLink to={generatePath("/leagues/:id/settings", {id})} {...props} />
);

export const LeagueAboutLink = (id: number) => ({to, ...props}: LinkProps) => (
	<NavLink to={generatePath("/league/:id/about", {id})} {...props} />
);

export const LeagueLadder = (id: number) => ({to, ...props}: LinkProps) => (
	<NavLink to={generatePath("/league/:id/ladder", {id})} {...props} />
);

export const PrizesLink = ({children}: {children: React.ReactElement | string}) => (
	<Link to={generatePath("/prizes")}>{children}</Link>
);

export const RulesLink = ({children}: {children: React.ReactElement | string}) => (
	<Link to={generatePath("/help/rules")}>{children}</Link>
);

export const TermsLink = ({children}: {children: React.ReactElement | string}) => (
	<Link to={generatePath("/terms")}>{children}</Link>
);

export const HelpPrizesLink = ({children}: {children: React.ReactElement | string}) => (
	<Link to={generatePath("/help/prizes")}>{children}</Link>
);
