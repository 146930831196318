export enum EnumRoundStatusType {
	SCHEDULED = "scheduled",
	ACTIVE = "active",
	COMPLETE = "complete",
}

export enum MatchStatusType {
	SCHEDULED = "scheduled",
	PLAYING = "playing",
	COMPLETE = "complete",
}

export enum ShareType {
	FB = "fb",
	TW = "tw",
}

export enum EnumLeagueStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum EnumLeaguePrivacy {
	Public = "public",
	Private = "private",
}

export enum EnumLeagueClass {
	Regular = "regular",
	Overall = "overall",
	Region = "region",
	Club = "club",
	Unknown = "unknown",
}

export enum OddsType {
	HeadToHead = "h2h",
	SuperBowl = "superBowl",
}

export enum ConfirmModal {
	Confirm,
	Decline,
}
