import {createReducer, Reducer} from "redux-act";
import {ILadderReducer} from "modules/types/reducers";
import {fetchTeamLadderSuccess} from "modules/actions";

const initialState: ILadderReducer = {
	ladder: {
		AFC: {
			ACN: [],
			ACE: [],
			ACW: [],
			ACS: [],
		},
		NFC: {
			NCS: [],
			NCN: [],
			NCE: [],
			NCW: [],
		},
	},
	next: false,
};
export const ladder: Reducer<ILadderReducer> = createReducer({}, initialState);
ladder.on(fetchTeamLadderSuccess, (state, payload) => ({
	...state,
	ladder: payload,
	next: false,
}));
