import {Storage} from "modules/utils/Storage";
import {IDictionary, IPrediction} from "modules/types";

export abstract class PredictionsUtils {
	private static _key = "predictions";
	private static _ttl = 60 * 60 * 1000;

	static SAVE(predictions: IDictionary<IPrediction>) {
		Storage.SET(PredictionsUtils._key, {
			data: predictions,
			_t: Date.now(),
		});
	}

	static CLEAR() {
		Storage.REMOVE(PredictionsUtils._key);
	}

	static GET() {
		const value = Storage.GET_JSON(PredictionsUtils._key) as {data: Record<string, any>; _t: number};
		const time = value?._t;

		if (Date.now() - time > PredictionsUtils._ttl || !time) {
			PredictionsUtils.CLEAR();
			return {};
		}

		return value.data || ({} as IDictionary<IPrediction>);
	}
}
