import {createReducer} from "redux-act";
import {IError} from "modules/types";
import {errorsClearGlobalError, errorsShowGlobalError} from "modules/actions";

const defaultState: IError = {
	text: "",
};

export const error = createReducer<IError>({}, defaultState);
error.on(errorsShowGlobalError, (state: IError, payload: IError) => ({
	...state,
	...payload,
}));
error.on(errorsClearGlobalError, () => defaultState);
