import React from "react";
import styled from "styled-components";

const CustomLabel = styled.label`
	position: relative;
	height: 20px;
	width: 50px;
	border: 1px solid #ccc;
	cursor: pointer;
	border-radius: 14px;
	background: #cccccc;
	display: flex;
	align-items: center;

	&:after {
		content: "";
		position: absolute;
		left: -3px;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		box-sizing: border-box;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const FormCircleCheckboxStyled = styled.div`
	input {
		visibility: hidden;
		width: 0;
		margin: 0;

		&:checked + label.custom-checkbox {
			background: #1b48e0;

			&:after {
				left: 50%;
			}
		}
	}
`;

interface IProps {
	readonly name: string;
	readonly disabled?: boolean;
	readonly checked: boolean | undefined;
	readonly value: string;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const FormCircleCheckbox = ({name, disabled, checked, onChange, value}: IProps) => (
	<FormCircleCheckboxStyled id={`id-${name}`}>
		<input
			type="checkbox"
			defaultChecked={checked}
			id={name}
			name={name}
			onChange={onChange}
			disabled={disabled}
			value={value}
		/>
		<CustomLabel className="custom-checkbox" htmlFor={name} />
	</FormCircleCheckboxStyled>
);
