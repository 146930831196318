import {isMobile} from "modules/utils";

export const getOmnitureTracking = (siteSubSection: string, pageDetail: string, user: any) => {
	const customVars = {
		page: {
			siteName: "nfl fantasy intl game",
			siteSection: "super bowl challenge",
			siteSubSection: `${siteSubSection}`,
			pageDetail: `${pageDetail}`,
			pageName: `nfl fantasy intl game:super bowl challenge:${siteSubSection}:${pageDetail}`,
			partner: "FanHub",
			optimizedFor: isMobile() ? "mobile web" : "desktop",
		},
		user: {
			gigyaUID: user.gigyaData ? user.gigyaData.gigya_id : "",
		},
	};

	if (window.dataLayer?.customVars) {
		window.dataLayer.customVars = customVars;
	}

	if (window.dataLayerUS?.customVars) {
		window.dataLayerUS.customVars = customVars;
	}
};
