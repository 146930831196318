import React from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {getErrorsState} from "modules/selectors";
import {Modal} from "components/Modal";
import {errorsClearGlobalError} from "modules/actions";

const ModalContentWrapper = styled.div`
	padding: 20px;
`;
const Title = styled.div`
	font-size: 20px;
	padding-bottom: 16px;
	font-weight: 500;
	margin-bottom: 18px;
	text-align: center;
`;

const Text = styled.div`
	font-size: 18px;
	margin: 30px 0;
	text-align: center;
	line-height: 1.5;
`;

export const GlobalErrors: React.FC = () => {
	const dispatch = useDispatch();
	const error = useSelector(getErrorsState);

	const onCloseClick = () => {
		dispatch(errorsClearGlobalError());
	};

	return error.text ? (
		<Modal onClose={onCloseClick}>
			<ModalContentWrapper onClick={onCloseClick}>
				<Title>Error</Title>
				<Text>{error.text}</Text>
			</ModalContentWrapper>
		</Modal>
	) : null;
};
