import {IBracketConfig} from "modules/types/brackets";
import {IPrediction, PickStatusEnum} from "modules/types";

export const WILD_CARD_ROUND_ID = 19;
export const BRACKETS_CONFIG: IBracketConfig = {
	size: 13,
	isSaved: false,
	selectedBracketID: 1,
	rounds: [
		{
			name: "bracket_round_bracket_round2",
			conference: "afc",
			id: "afcWild",
			roundID: 19,
			brackets: [
				{
					seeds: [[5], [4]],
					bracketID: 1,
					isSingle: true,
					lineType: "zBottom",
					conference: "afc",
					roundID: 19,
				},
				{
					seeds: [[6], [3]],
					bracketID: 2,
					lineType: "zDouble",
					conference: "afc",
					roundID: 19,
				},
				{
					seeds: [[7], [2]],
					bracketID: 3,
					lineType: "zTop",
					conference: "afc",
					roundID: 19,
				},
			],
		},
		{
			name: "bracket_round_bracket_round3",
			conference: "afc",
			id: "afcDev",
			roundID: 20,
			brackets: [
				{
					seeds: [[], [1]],
					bracketID: 7,
					lineType: "zBottom",
					prevID: [1, 2, 3],
					conference: "afc",
					roundID: 20,
				},
				{
					seeds: [[], []],
					bracketID: 8,
					lineType: "zTop",
					prevID: [1, 2, 3],
					conference: "afc",
					roundID: 20,
				},
			],
		},

		{
			name: "bracket_round_bracket_round7",
			conference: "afc",
			id: "afcConf",
			roundID: 21,
			brackets: [
				{
					seeds: [[], []],
					bracketID: 11,
					isSingle: true,
					lineType: "str",
					prevID: [7, 8],
					conference: "afc",
					roundID: 21,
				},
			],
		},
		{
			name: "Final",
			conference: null,
			id: "sb",
			roundID: 22,
			brackets: [
				{
					seeds: [[], []],
					bracketID: 13,
					isSingle: true,
					lineType: "tripleStr",
					prevID: [11, 12],
					conference: null,
					roundID: 22,
				},
			],
		},
		{
			name: "bracket_round_bracket_round4",
			conference: "nfc",
			id: "nfcConf",
			roundID: 21,
			brackets: [
				{
					seeds: [[], []],
					bracketID: 12,
					isSingle: true,
					lineType: "str",
					prevID: [9, 10],
					conference: "nfc",
					roundID: 21,
				},
			],
		},
		{
			name: "bracket_round_bracket_round5",
			conference: "nfc",
			id: "nfcDev",
			roundID: 20,
			brackets: [
				{
					seeds: [[0], [1]],
					bracketID: 9,
					isSingle: true,
					lineType: "zBottom",
					prevID: [4, 5, 6],
					conference: "nfc",
					roundID: 20,
				},
				{
					seeds: [[], []],
					bracketID: 10,
					lineType: "zTop",
					prevID: [4, 5, 6],
					conference: "nfc",
					roundID: 20,
				},
			],
		},
		{
			name: "bracket_round_bracket_round6",
			conference: "nfc",
			id: "nfcWild",
			roundID: 19,
			brackets: [
				{
					seeds: [[5], [4]],
					bracketID: 4,
					isSingle: true,
					lineType: "zBottom",
					conference: "nfc",
					roundID: 19,
				},
				{
					seeds: [[6], [3]],
					bracketID: 5,
					lineType: "zDouble",
					conference: "nfc",
					roundID: 19,
				},
				{
					seeds: [[7], [2]],
					bracketID: 6,
					lineType: "zTop",
					conference: "nfc",
					roundID: 19,
				},
			],
		},
	],
};

export const DEFAULT_PREDICTION: IPrediction = {
	id: 0,
	round: 0,
	squad_id: "",
	points: 0,
	max_points: 0,
	seed: 0,
	conference: "afc",
	status: PickStatusEnum.Default,
	bracket_id: 0,
	real_bracket_id: 0,
};

export const SQUAD_COLORS = {
	[PickStatusEnum.Picked]: {
		background: "#EAF7FF",
		border: "#007DC3",
	},
	[PickStatusEnum.Correct]: {
		background: "#F6FFF2",
		border: "#3CB200",
	},
	[PickStatusEnum.Failed]: {
		background: "#FFF9F9",
		border: "#E80000",
	},
	[PickStatusEnum.RePick]: {
		background: "#F8EDFF",
		border: "#5C019C",
	},
	[PickStatusEnum.Default]: {
		background: "#fafafa",
		border: "#f0f0f0",
	},
} as const;
