import React from "react";
import styled from "styled-components";
import theme from "assets/css/theme";
import {size, first, filter, get} from "lodash";
import {PrizeWidgetContent} from "./PrizeWidgetContent";
import {ScoreWidgetContent} from "./ScoreWidgetContent";

const WidgetWrapper = styled.div`
	max-width: 380px;
	width: 100%;
	border-radius: 4px;
	background-color: #fff;
	min-height: 280px;
	font-family: ${theme.base_font};
	margin-bottom: 30px;
	padding: 20px 0;
	border-bottom: 10px solid #1b48e0;

	> div {
		margin-bottom: 20px;
	}
`;

const Title = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 28px;
	line-height: 34px;
	color: #333;
	margin-bottom: 20px;
`;

const More = styled.span`
	margin: 0 20px;
	padding: 3px 0;
	color: ${theme.placeholder};
	font-size: 14px;
	line-height: 22px;
	border-bottom: 1px solid ${theme.placeholder};
	cursor: pointer;
`;

const MoreLink = styled.a``;

interface IProps {
	widget: {
		title: string | undefined;
		type: string;
		content?: any;
		more?: string;
		more_link?: any;
	};
}

export const Widget = ({widget: {content, title, more_link, more, type}}: IProps) => {
	const has_content = size(content) > 0;
	const widget_content = first(filter(content, (item) => get(item, "tags") === "sidebar"));

	return (
		<React.Fragment>
			<Title>{title}</Title>
			<WidgetWrapper>
				{has_content && widget_content ? (
					<React.Fragment>
						{type === "prizes" ? (
							// @ts-ignore
							<PrizeWidgetContent content={widget_content.text} />
						) : null}
						{type === "scores" ? (
							// @ts-ignore
							<ScoreWidgetContent content={widget_content.content} />
						) : null}

						<More>
							<MoreLink as={more_link}>{more}</MoreLink>
						</More>
					</React.Fragment>
				) : null}
			</WidgetWrapper>
		</React.Fragment>
	);
};
