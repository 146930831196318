import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Exist, IconArrowSlim} from "components";
import {useDispatch} from "react-redux";
import {setHowToPlayModalStateAction} from "modules/actions";
import {IS_HIDDEN_PRIZES} from "modules/constants";

const Wrapper = styled.div`
	display: block;
`;

const NavLinkStyled = styled(NavLink)`
	display: flex;
	align-items: center;
	height: 60px;
	color: #151515;
	cursor: pointer;
	&.active {
		background-color: #ffffff;
		border-radius: 2px;

		svg {
			color: #1b48e0;
		}
	}

	svg {
		margin: 0 15px;
	}
`;

export const HelpWidget: React.FC = () => {
	const dispatch = useDispatch();
	const WIDTH_ARROW = 14;
	const showHowToPlayModal = () => {
		dispatch(setHowToPlayModalStateAction(true));
	};

	return (
		<Wrapper>
			<NavLinkStyled to="/help/rules">
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				{window.getTranslations("help_list_label_rules")}
			</NavLinkStyled>

			<Exist when={!IS_HIDDEN_PRIZES}>
				<NavLinkStyled to="/help/prizes">
					<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
					{window.getTranslations("pickem_nav_button_prizes")}
				</NavLinkStyled>
			</Exist>

			<NavLinkStyled to="/help/faqs">
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				FAQ
			</NavLinkStyled>
			<NavLinkStyled as={"div"} onClick={showHowToPlayModal}>
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				{window.getTranslations("pickem_footer_hyperlink_howtoplay")}
			</NavLinkStyled>
			<NavLinkStyled to="/help/contact-us">
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				{window.getTranslations("help_list_label_contact")}
			</NavLinkStyled>

			<NavLinkStyled as="a" href="https://www.nfl.com/legal/privacy/" target={"blank"}>
				<IconArrowSlim color="#555555" to="right" width={WIDTH_ARROW} />
				{window.getTranslations("pickem_footer_hyperlink_privacypolicy")}
			</NavLinkStyled>
		</Wrapper>
	);
};
