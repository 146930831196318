import styled from "styled-components";
import selectArrowIcon from "assets/img/icons/select_arrow.svg";

interface IInput {
	readonly is_error?: boolean;
}

export const Select = styled.select`
	height: 40px;
	width: 100%;
	border: 1px solid ${({is_error}: IInput) => (is_error ? "red" : "#CCCCCC")};
	color: #757575;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 10px;
	background: url(${selectArrowIcon}) no-repeat right 10px center #ffffff;
	margin-top: 5px;
	appearance: none;
	font-size: 16px;

	&:focus {
		border-color: #1b48e0;
		color: #333333;
	}
`;
