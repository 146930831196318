import {
	fetchFAQSSuccess,
	fetchGuidelinesSuccess,
	fetchHelpFinished,
	fetchPrivacyPolicySuccess,
	fetchPrizesSuccess,
	fetchRulesSuccess,
	helpRequested,
} from "modules/actions/helps";
import {IHelpReducer} from "modules/types";
import {createReducer} from "redux-act";

const initialState: IHelpReducer = {
	guidelines: [],
	terms: [],
	faqs: [],
	prizes: [],
	rules: [],
	privacy_policy: [],
	is_data_requested: false,
};

const mergeJson = (state: IHelpReducer, payload: Partial<IHelpReducer>) => ({
	...state,
	...payload,
	is_data_requested: false,
});

export const jsons = createReducer<IHelpReducer>({}, initialState)
	.on(helpRequested, (state: IHelpReducer) => ({
		...state,
		is_data_requested: true,
	}))

	.on(fetchHelpFinished, (state: IHelpReducer) => ({
		...state,
		is_data_requested: false,
	}))
	.on(fetchFAQSSuccess, mergeJson)
	.on(fetchPrizesSuccess, mergeJson)
	.on(fetchRulesSuccess, mergeJson)
	.on(fetchGuidelinesSuccess, mergeJson)
	.on(fetchPrivacyPolicySuccess, mergeJson);

export * from "./rounds";
export * from "./squads";
export * from "./team_statistics";
