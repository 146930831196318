import {SagaIterator} from "redux-saga";
import {call, put, select, take} from "redux-saga/effects";
import {Api, ApiError} from "modules/utils/Api";
import {getPredictionsSelector} from "modules/selectors/predictions";
import {chain, keyBy, pickBy, mapValues, keys, get, isEmpty} from "lodash";
import {
	cleanUpPicksAction,
	errorsGlobalError,
	fetchPredictionsSuccessAction,
	fetchRoundSuccessAction,
	savePredictionsListSuccessAction,
	setGlobalLoaderStateAction,
	setRePickModalState,
	showConfirmSaveStoredPredictions,
	answerConfirmSaveStoredPredictions,
} from "modules/actions";
import {updateBracketConfig, updateBracketsSavedStateAction} from "modules/actions/brackets";
import {cleanUpPredictionsBracketID, fillBracketsByPredictionsList} from "modules/sagas/brackets";
import {ISagaAction, PickStatusEnum, IUser} from "modules/types";
import {DEFAULT_PREDICTION} from "modules/constants";
import {getUserDataSelector, getRounds} from "modules/selectors";
import {SSOUtils} from "modules/utils/sso";
import {ConfirmModal} from "modules/constants/enums";
import {PredictionsUtils} from "modules/utils/predictions";

export const savePredictionsListSaga = function* (): SagaIterator {
	const user = yield select(getUserDataSelector);

	try {
		yield put(setGlobalLoaderStateAction(true));
		const predictions = yield select(getPredictionsSelector);

		const predictionsToSave = chain(predictions)
			.values()
			.map((prediction) => {
				const opponentSquadId = get(prediction, "opponent_squad_id", null);

				return {
					round: prediction.round,
					squad_id: prediction.squad_id,
					bracket_id: prediction.bracket_id,
					opponent_squad_id: opponentSquadId,
				};
			})
			.value();

		if (user?.id) {
			const response = yield call(Api.Predictions.saveList, {
				predictions: predictionsToSave,
			});

			ApiError.CHECK(response);

			const predictionsToStore = keyBy(response.result.predictions, "bracket_id");
			yield put(savePredictionsListSuccessAction(predictionsToStore));
			yield put(setGlobalLoaderStateAction(false));
			yield put(updateBracketsSavedStateAction(true));
			//
			// if (eq(SYNDICATE_COUNTRY, "BR")) {
			// 	yield put(showBRSponsorModal());
			// }
		} else {
			PredictionsUtils.SAVE(predictions);
			yield put(updateBracketsSavedStateAction(true));
			yield put(setGlobalLoaderStateAction(false));

			SSOUtils.LOGIN();
		}
	} catch (e) {
		console.log(e);
		yield put(errorsGlobalError(e));
		yield put(updateBracketsSavedStateAction(false));
	}
};

export const fetchPredictionsSaga = function* (): SagaIterator {
	const rounds = yield select(getRounds);
	if (!rounds.length) {
		yield take(fetchRoundSuccessAction);
	}
	const user = yield select(getUserDataSelector);

	try {
		yield put(setGlobalLoaderStateAction(true));
		if (user.id && user.recovered) {
			const response = yield call(Api.Predictions.fetchList);
			const gameBarResponse = yield call(Api.Predictions.fetchGameBar);

			ApiError.CHECK(response);
			ApiError.CHECK(gameBarResponse);
			const predictionsToStore = keyBy(response.result.predictions, "bracket_id");

			yield put(fetchPredictionsSuccessAction({...predictionsToStore, gameBar: gameBarResponse.result}));

			if (response.result.predictions) {
				yield put(updateBracketsSavedStateAction(true));
			}
		} else {
			const predictions = PredictionsUtils.GET();

			yield put(fetchPredictionsSuccessAction({...predictions}));
		}
		yield call(rePickSaga);
		yield put(setGlobalLoaderStateAction(false));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const fetchSharedPredictionsSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	yield take(fetchRoundSuccessAction);
	try {
		yield put(setGlobalLoaderStateAction(true));
		const response = yield call(Api.Predictions.fetchSharedList, {user_id: payload});
		ApiError.CHECK(response);
		const predictionsToStore = keyBy(response.result.predictions, "bracket_id");
		const {configData} = yield call(fillBracketsByPredictionsList, predictionsToStore);

		yield put(updateBracketConfig(configData));
		yield put(fetchPredictionsSuccessAction(predictionsToStore));
		yield put(setGlobalLoaderStateAction(false));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const checkPredictionsForSave = function* (action: ISagaAction<IUser>): SagaIterator {
	const user = action.payload;
	const rounds = yield select(getRounds);
	if (!rounds.length) {
		yield take(fetchRoundSuccessAction);
	}

	if (!user.recovered) {
		return;
	}

	yield call(fetchPredictionsSaga);
	try {
		const storagePredictions = PredictionsUtils.GET();

		if (isEmpty(storagePredictions)) {
			return;
		}

		yield put(setGlobalLoaderStateAction(true));
		const response = yield call(Api.Predictions.fetchList);
		ApiError.CHECK(response);

		yield put(setGlobalLoaderStateAction(false));

		const hasSavedPredictions = !isEmpty(response.result.predictions);

		if (hasSavedPredictions) {
			yield put(showConfirmSaveStoredPredictions());
			const modalResponse = yield take(answerConfirmSaveStoredPredictions);

			if (modalResponse.payload === ConfirmModal.Decline) {
				return;
			}
		}

		yield put(fetchPredictionsSuccessAction({...storagePredictions}));
		yield call(savePredictionsListSaga);

		PredictionsUtils.CLEAR();
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const rePickSaga = function* (): SagaIterator {
	const predictions = yield select(getPredictionsSelector);
	const toRePickPredictions = pickBy(predictions, {status: PickStatusEnum.RePick});
	const localStorageModalState = localStorage.getItem("rePickModalShown");
	if (keys(toRePickPredictions).length && localStorageModalState !== "1") {
		yield put(setRePickModalState(true));
	}

	const safePicks = chain(predictions)
		.values()
		.filter((prediction) => prediction.status !== PickStatusEnum.RePick)
		.mapValues((prediction) => {
			if (prediction.real_bracket_id) {
				prediction.bracket_id = prediction.real_bracket_id;
				prediction.real_bracket_id = 0;
			}
			return prediction;
		})
		.keyBy("bracket_id")
		.value();
	const toDefaults = mapValues(toRePickPredictions, () => ({
		...DEFAULT_PREDICTION,
		max_points: 0,
	}));
	yield put(cleanUpPicksAction(toDefaults));
	const {brackets, configData} = yield call(fillBracketsByPredictionsList, safePicks);
	const cleanedPredictions = cleanUpPredictionsBracketID(safePicks, brackets);
	yield put(cleanUpPicksAction(cleanedPredictions));
	yield put(updateBracketConfig(configData));
};
