import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios from "axios";
import {set, get, snakeCase} from "lodash";
import {Storage} from "modules/utils/Storage";
import {CANCEL} from "redux-saga";
import {CONFIG_COUNTRY, IS_API_ON_THE_SAME_HOST, SYNDICATE_COUNTRY} from "modules/constants";
import * as qs from "qs";
import {
	IFetchSharedPredictions,
	IFetchTeamStatistics,
	IGigyaBackdoorParams,
	IGigyaLoginParams,
	ILadderPayload,
	ILeagueCreate,
	IRegisterParams,
	ISavePredictionsParams,
	IUpdateUserAction,
} from "modules/types";
import {IContactUSSagaAction} from "modules/sagas/contact_us";
import {IEmailInvites} from "modules/sagas/leagues";
import {IFetchSharingImgUrl, ISendIncrement} from "modules/actions";
import {User} from "modules/utils/User";
// import { IUserLoginPayload, IUserUpdatePayload, IUserInactivePayload } from 'modules/types/global-store';
// import { IContactUsPayload } from 'modules/actions/help';
export class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
			params: {
				_: Date.now(),
				...config.params,
				...(IS_API_ON_THE_SAME_HOST ? {} : {sid: Storage.GET("sid") ?? ""}),
			},
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_URL || "",
});
const baseApiURL: string = process.env.REACT_APP_API_URL || "";

const getBrowserLanguage = () => {
	const browserLanguage = String(window.navigator.language || "es").toLowerCase();
	if (browserLanguage === "es-es") {
		return "es";
	} else if (browserLanguage === "en-en") {
		return "en";
	} else if (!["es", "en"].includes(browserLanguage)) {
		return "es";
	}
	return browserLanguage;
};

const getUserLang = () => {
	const userLang = Storage.GET("userLang") || null;
	return userLang || getBrowserLanguage();
};

export const getLang = () => {
	const country = SYNDICATE_COUNTRY || "";
	if (country === "US") {
		const user = User.GET();
		return user.predictor.lang?.length ? user.predictor.lang : getUserLang();
	}
	return window.LANGUAGE || process.env.REACT_APP_DEFAULT_LANG || "en";
};

const getBaseURL = () => {
	return `${baseApiURL}${getLang()}/api/`;
};
const APIClient = new HTTPClient({
	baseURL: "",
	withCredentials: true,
	transformRequest: [(data) => qs.stringify(data)],
	onCatchNetworkError: (response) => get(response, "response.data"),
	onBeforeRequest(config) {
		return {
			...config,
			baseURL: getBaseURL(),
		};
	},
});

const LocaleClient = new HTTPClient({
	baseURL: process.env.REACT_APP_LOCALE || "",
});

// const JSONHelpClient = new HTTPClient({
// 	baseURL: process.env.REACT_APP_HELP_URL || '',
// });

const JSONStaticFilesClient = new HTTPClient({
	baseURL: "",
	onBeforeRequest(config) {
		const base_url = process.env.REACT_APP_JSON_URL || "";
		const country = String(CONFIG_COUNTRY);
		const url = config.url || "";

		if (country === "ca" && window.LANGUAGE !== "en") {
			return {
				...config,
				url: "",
				baseURL: `${base_url}/predictor_${snakeCase(window.LANGUAGE)}_${url}`,
			};
		}
		return {
			...config,
			url: "",
			baseURL: `${base_url}/predictor_${country}_${url}`,
		};
	},
});

export const Api = {
	JSON: {
		ladder: () => JSONClient.get("predictor/ladder/2020.json"),
		rounds: () => JSONClient.get("predictor/rounds.json"),
		squads: () => JSONClient.get("predictor/squads.json"),
		prizes: () => JSONStaticFilesClient.get(`prizes.json`),
		faq: () => JSONStaticFilesClient.get(`faq.json`),
		guidelines: () => JSONStaticFilesClient.get(`gameguidelines.json`),
		rules: () => JSONStaticFilesClient.get(`gamerules.json`),
		privacy_policy: () => JSONStaticFilesClient.get(`terms.json`),
		team_statistics: ({home_id, away_id}: IFetchTeamStatistics) =>
			JSONClient.get(`predictor/formguide/${home_id}_${away_id}.json`),
		checksums: () => JSONClient.get("predictor/checksums.json"),
	},
	Auth: {
		gigyaLogin: (params: IGigyaLoginParams) => APIClient.post(`gigya_login`, params),
		gigyaBackdoor: (params: IGigyaBackdoorParams) => APIClient.post(`gigya_login/backdoor`, params),
		registerForGame: (params: IRegisterParams) => APIClient.post(`user/register_for_game`, params),
		userSession: () => APIClient.get("user/session"),
		updateUser: (params: IUpdateUserAction) => APIClient.post("user/update", params),
		logout: () => APIClient.post("/logout"),
		deactivate: () => APIClient.post("user/deactivate"),
		recover: () => APIClient.post("user/recover"),
	},
	Global: {
		translations: (lang: string) => LocaleClient.get(`${lang}/nfl.json`),
		country: () => APIClient.get("geo_ip/country"),
	},
	League: {
		create: (params: ILeagueCreate) => APIClient.post("predictor_league/create", params),
		show_my: (params: Record<string, unknown>) => APIClient.get("predictor_league/show_my", params),
		show: (params: Record<string, unknown>) => APIClient.get("predictor_league/show", params),
		ladder: (params: ILadderPayload) => APIClient.get("predictor_league/ladder", params),
		update: (params: ILeagueCreate) => APIClient.post("predictor_league/update", params),
	},
	Joins: {
		show_for_join: (params: Record<string, unknown>) =>
			APIClient.get("predictor_join/show_leagues_for_join", params),
		join: (params: Record<string, unknown>) => APIClient.post("predictor_join/join", params),
		leave: (params: Record<string, unknown>) => APIClient.post("predictor_join/leave", params),
		invite: (params: IEmailInvites) => APIClient.post("predictor_invite/invite", params),
	},
	Contact: {
		save: (params: IContactUSSagaAction) => APIClient.post("contact", params),
	},
	Predictions: {
		saveList: (params: ISavePredictionsParams) => APIClient.post("prediction/save_list", params),
		fetchList: () => APIClient.get("prediction/show_my"),
		fetchSharedList: (params: IFetchSharedPredictions) => APIClient.get("prediction/shared", params),
		fetchGameBar: () => APIClient.get("predictor_stats/gamebar"),
	},
	Share: {
		imgUrl: (params: IFetchSharingImgUrl) => APIClient.get("share/predictor_dynamic_image", params),
		increment: (params: ISendIncrement) => APIClient.post("share/increment", params),
	},
};

export * from "modules/utils/Api/ApiError";
