import {ILadderTeam, ISquad, IStore} from "modules/types";
import {createSelector} from "reselect";
import {mapValues, chain, find, flatten, get} from "lodash";
import {getSquads} from "modules/selectors/squads";

export const getTeamsLadderStore = ({ladder}: IStore) => ladder;
export const getTeamsLadder = createSelector(getTeamsLadderStore, ({ladder}) => ladder);
export const getTeamLadderTeamsList = createSelector([getTeamsLadder, getSquads], (ladder, squads) => {
	const teamsList: ISquad[] = [];
	mapValues(ladder, (conf) => {
		const sectionsList = chain(conf)
			.values()
			.flatten()
			// @ts-ignore
			.map((team: ILadderTeam) => {
				const squad = find(squads, {id: team.team_id}) || {};
				if (squad) {
					return {
						...squad,
						seed: team.conference_rank,
					};
				}
				return team;
			})
			.value();
		teamsList.push(sectionsList);
	});
	return flatten(teamsList);
});

export const hasLadderNextLoadMore = ({ladder}: IStore): boolean => get(ladder, "next", false);
