import {useSelector} from "react-redux";
import {getScheduledRound} from "modules/selectors";
import {IDictionary} from "modules/types";
import {get} from "lodash";

const pow: IDictionary<number> = {
	2: 1,
	4: 2,
	8: 4,
	16: 8,
};

export const useMaxPoints = (round: number, maxPoints: number) => {
	const scheduledRound = useSelector(getScheduledRound);
	const firstRoundID = get(scheduledRound, "id", 19);
	const potentialPoints = round === 19 ? 2 : Math.max(2, Math.pow(2, round - firstRoundID + 1));
	const multiply = maxPoints ? pow[maxPoints] : pow[potentialPoints];
	return {
		maxPoints: maxPoints || potentialPoints,
		multiply,
	};
};
