import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {getLang} from "modules/utils/Api";
import {CONFIG_COUNTRY} from "modules/constants";

export const getUser = ({user}: IStore) => user.user;

export const selectedLang = createSelector(getUser, () => {
	const currentLang = getLang();
	return ["en", "es"].includes(currentLang) ? currentLang : "es";
});

export const isMXSelector = createSelector(selectedLang, (_lang) => {
	return CONFIG_COUNTRY === "us";
});
