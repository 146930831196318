import {createAction} from "redux-act";
import {IDictionary, IPickAction, IPrediction} from "modules/types";
import {ConfirmModal} from "modules/constants/enums";

export const makePickAction = createAction<IPickAction>();
export const makePickActionSuccess = createAction<IPickAction>();
export const cleanUpPicksAction = createAction<IDictionary<IPrediction>>();

export const savePredictionsListAction = createAction();
export const savePredictionsListSuccessAction = createAction<IDictionary<IPrediction>>();

export const fetchPredictionsAction = createAction();
export const fetchPredictionsSuccessAction = createAction<IDictionary<IPrediction>>();

export const fetchSharedPredictionsAction = createAction<number>();
export const fetchSharedPredictionsSuccessAction = createAction<IDictionary<IPrediction>>();

export const rePickAction = createAction();
export const showConfirmSaveStoredPredictions = createAction();
export const answerConfirmSaveStoredPredictions = createAction<ConfirmModal>();
export const hideConfirmSaveStoredPredictions = createAction();
