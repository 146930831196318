import styled from "styled-components";
import {NavLink} from "react-router-dom";
import theme from "assets/css/theme";

export const WrapperMobile = styled.div<{
	isHideItem: boolean;
	isSub?: boolean;
}>`
	background: ${(props) => (props.isSub ? "#12156F" : "#1d22a9")};
	height: ${(props) => (props.isSub ? "50px" : "61px")};
	width: 100%;
	color: #ffffff;
	font-size: 14px;
	font-family: ${(props) => (props.isSub ? theme.base_font : theme.mediumFont)};
	border-bottom: 1px solid;
	border-color: ${(props) => (props.isSub ? "rgba(255,255,255, 0.1)" : "#12156F")};
	display: ${(props) => (props.isHideItem ? "none" : "flex")};
`;

export const NavItemMobile = styled(NavLink)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	box-shadow: none;
	background: none;
	cursor: pointer;
	padding: 0;
	line-height: 1;
	color: currentColor;
	img {
		margin-left: 10px;
	}
`;

export const DropdownItemMobile = styled.div<{isOpen?: boolean}>`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		filter: invert(1);
		transform: rotate(${(props) => (props.isOpen ? "0deg" : "180deg")});
		margin-left: 10px;
	}
`;
