import React, {useCallback, useEffect, useState} from "react";
import {get} from "lodash";
import {PagePreloader} from "components/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {gigyaLoginAction, logoutAction, setGigyaDataAction, getSessionAction} from "modules/actions/auth";
import {isAuthedSelector} from "modules/selectors";
// @ts-ignore
import GigyaWrapper from "gigya-api-wrapper";
import {useLocation} from "react-router-dom";

const gigyaInstance = new GigyaWrapper({
	apiKey: process.env.REACT_APP_GIGYA_KEY || "test",
	lang: process.env.REACT_APP_DEFAULT_LANG || "",
	https: window.location.protocol === "https:",
});

window.gigyaInstance = gigyaInstance;

export const GigyaContext = React.createContext(gigyaInstance);

export const GigyaGate: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const [isGigyaReady, setIsGigyaReady] = useState(true);
	const isGigActions = new URLSearchParams(useLocation().search).get("gig_actions");
	const isAuthed = useSelector(isAuthedSelector);

	const loginHandler = useCallback(
		(res: unknown) => {
			const email = get(res, "profile.email", "") as string;
			const gigya_id = get(res, "UID", "") as string;
			const gigya_signature = get(res, "UIDSignature", "") as string;
			const gigya_signature_timestamp = get(res, "signatureTimestamp", "") as string;
			const first_name = get(res, "profile.firstName", "") as string;
			const last_name = get(res, "profile.lastName", "") as string;
			const birthday = [
				get(res, "profile.birthYear", ""),
				get(res, "profile.birthMonth", ""),
				get(res, "profile.birthDay", ""),
			].join("-");
			const postcode = get(res, "profile.zip") as string;
			const favorite_team = get(res, "data.league.favoriteTeams.0", "0") as string;
			const gigyaData = {
				email,
				gigya_id,
				gigya_signature,
				gigya_signature_timestamp,
				first_name,
				last_name,
				birthday,
				postcode,
				favorite_team,
			};
			dispatch(setGigyaDataAction(gigyaData));
			if (!isAuthed) {
				dispatch(gigyaLoginAction(gigyaData));
			}
		},
		[dispatch, isAuthed]
	);

	const logoutHandler = useCallback(() => {
		dispatch(logoutAction());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getSessionAction());
		gigyaInstance.onLibraryReady().then((gigya: any) => {
			setIsGigyaReady(true);
			gigyaInstance
				.getAccountInfo("id_token,profile,data")
				.then((response: any) => {
					loginHandler(response);
				})
				.catch((e: any) => {
					setIsGigyaReady(true);
				});
			gigyaInstance.registerEventListeners({
				onLogout: logoutHandler,
			});
		});
		// eslint-disable-next-line
	}, [dispatch]);

	if (isGigActions) {
		window.location.search = "";
	}

	return (
		<GigyaContext.Provider value={gigyaInstance}>
			{isGigyaReady ? <React.Fragment>{children}</React.Fragment> : <PagePreloader />}
		</GigyaContext.Provider>
	);
};
