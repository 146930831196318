import React, {useEffect} from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import theme from "assets/css/theme";
import shareIcon from "assets/img/icons/Share-grey.svg";
import {useDispatch, useSelector} from "react-redux";
import {fetchSharingImgUrl, sendIncrementQuery} from "modules/actions";
import {getShareUrl} from "modules/selectors";
import {SYNDICATE_COUNTRY} from "modules/constants";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
	button {
		max-width: 250px;
	}
	img {
		width: 295px;
	}
`;
const Icon = styled.img`
	width: 100%;
	max-width: 30px;
	margin-bottom: 20px;
`;
const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 21px;
	text-align: center;
`;

const Description = styled.div`
	margin-top: 20px;
`;

interface IProps {
	openOutsideState: boolean;
	changeShareImageModalState: (param: boolean) => void;
}
export const ShareBracketsImageModal: React.FC<IProps> = ({openOutsideState, changeShareImageModalState}) => {
	const dispatch = useDispatch();
	const shareUrl = useSelector(getShareUrl);

	useEffect(() => {
		if (openOutsideState) {
			dispatch(
				sendIncrementQuery({
					type: "predictor",
					social: "bracket_generate",
				})
			);
			const country = SYNDICATE_COUNTRY || "GB";
			dispatch(fetchSharingImgUrl({orientation: "mobile", country}));
		}
	}, [dispatch, openOutsideState]);

	const closeClick = () => {
		changeShareImageModalState(false);
	};

	return openOutsideState ? (
		<Modal onClose={closeClick} isCloseable={true} width="55%">
			<Wrapper>
				<Icon src={shareIcon} alt={"Share Icon"} />
				<Title>{window.getTranslations("bracket_image.modal.title")}</Title>
				{shareUrl ? <img src={shareUrl} alt={"Share img"} /> : null}
				<Description>{window.getTranslations("bracket_image.modal.instructions")}</Description>
			</Wrapper>
		</Modal>
	) : null;
};
