import {createAction} from "redux-act";
import {IError} from "modules/types";

export const setGlobalLoaderStateAction = createAction<boolean>();
export const setRoundsFetchedStateAction = createAction<boolean>();
export const fetchCountryAction = createAction();
export const fetchCountrySuccessAction = createAction<string>();
export const setRePickModalState = createAction<boolean>();
export const setHowToPlayModalStateAction = createAction<boolean>();

export const errorsGlobalError = createAction<TypeError | Error | string | unknown>();
export const errorsShowGlobalError = createAction<IError>();
export const errorsClearGlobalError = createAction();

export const setHighlightVideoAction = createAction<string>();
