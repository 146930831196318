import styled from "styled-components";
import {RegularButton} from "./RegularButton";
import theme from "assets/css/theme";

export const RegularButtonLight = styled(RegularButton)`
	background: #fff;
	border: 1px solid #cccccc;
	color: ${theme.primary};
	margin-bottom: 0.5em;
	text-transform: uppercase;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	@media screen and (max-width: 840px) {
		margin: 0.5em auto;
	}
`;
