import styled from "styled-components";
import theme from "assets/css/theme";

export const LeagueToggleButton = styled.div`
	font-size: 14px;
	color: ${theme.placeholder};
	line-height: 22px;
	font-family: ${theme.base_font};
	border-bottom: 1px solid ${theme.placeholder};
	cursor: pointer;
`;
