import React from "react";
import {ExternalLink} from "components/Link";
import skyBetLogo from "assets/img/skyBetLogo.svg";
import logo888 from "apps/GB/assets/img/888logos.svg";
import logo888White from "apps/GB/assets/img/888logosWhite.svg";
import fandueLogo from "apps/CA/assets/img/sponsor-fandue-color.png";
import styled from "styled-components";

const Logo = styled.img`
	width: 26px;
`;

export const SkyBetLogo: React.FC = (props) => (
	<ExternalLink href="https://www.skybet.de/" {...props}>
		<img src={skyBetLogo} alt="Sky Bet" />
	</ExternalLink>
);

export const Betting888Logo: React.FC = (props) => (
	<ExternalLink href="https://www.888sport.com/" {...props}>
		<Logo src={logo888} alt="888 sport" />
	</ExternalLink>
);

export const Betting888LogoWhite: React.FC = (props) => (
	<ExternalLink href="https://www.888sport.com/" {...props}>
		<Logo src={logo888White} alt="888 sport" />
	</ExternalLink>
);

const LogoBettingFandue = styled.img`
	width: 75px;
`;

export const BettingFandueLogo: React.FC = (props) => (
	<div>
		<LogoBettingFandue src={fandueLogo} alt="fandue sport" />
	</div>
);
