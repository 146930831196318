const BASE = "";
const VERSION = process.env.REACT_APP_STORAGE_VERSION || "";

/**
 * Wrapper for localStorage that made storage key uniq for any base path.
 * It's required for multiple projects on the same domain but at different folders
 */
export abstract class Storage {
	/**
	 * Store key/value data in localStorage
	 */
	public static SET(key: string, value: unknown): void {
		const val = typeof value === "string" ? value : JSON.stringify(value || "");
		localStorage.setItem(Storage.generate_key(key), val);
	}

	/**
	 * Get value by key from localStorage
	 */
	public static GET(key: string) {
		return localStorage.getItem(Storage.generate_key(key));
	}

	/**
	 * Remove value by key from localStorage
	 */
	public static REMOVE(key: string): void {
		localStorage.removeItem(Storage.generate_key(key));
	}

	/**
	 * Remove all values from localStorage
	 */
	public static FLUSH(): void {
		localStorage.clear();
	}

	private static generate_key(key: string): string {
		return BASE + key + "-" + VERSION;
	}

	/**
	 * Get value by key from localStorage
	 */
	public static GET_JSON(key: string) {
		const storedValue = Storage.GET(key);
		if (!storedValue) {
			return null;
		}

		try {
			return JSON.parse(storedValue) as Record<string, any>;
		} catch (e) {
			console.error(`Removed ${key}. Failed to parse stored value of ${key}: ${String(storedValue)}`);
			Storage.REMOVE(key);
			return {};
		}
	}
}
