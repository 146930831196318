import * as React from "react";
import {IIconProps} from "modules/types";

export const IconSpinner: React.FC<IIconProps> = ({
	title,
	width = 120,
	height = 120,
	color,
	onClick,
}) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 120 120" className="loader">
		<title>{title}</title>

		<circle className="internal-circle" cx="60" cy="60" r="30" />
		<circle className="external-circle" cx="60" cy="60" r="50" />
	</svg>
);

IconSpinner.defaultProps = {
	title: "Spinner",
	color: "#fff",
};
