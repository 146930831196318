import React from "react";
import {IInviteEmails, IInvitesReducer, IMyLeague, IStore} from "modules/types";
import {connect} from "react-redux";
import {bindAll, filter, findLastIndex, get, partial} from "lodash";
import {RegularButton, PagePreloader} from "components";
import styled from "styled-components";
import {Input} from "components/Form";
import theme from "assets/css/theme";
import plus from "assets/img/icons/plus.png";
import clear from "assets/img/icons/Cross.svg";
import {inviteViaEmail, inviteViaEmailClear} from "modules/actions";

const Notify = styled.div`
	color: ${theme.primary};
	font-family: ${theme.base_font};
	margin-top: 10px;
`;

const SubmitButton = styled(RegularButton)`
	max-width: 200px;
	margin-top: 30px;

	@media screen and (max-width: 820px) {
		max-width: 100%;
	}
`;

const InviteFieldsForm = styled.form`
	margin-bottom: 30px;
`;

const InviteFields = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	> * {
		margin: 0 1%;
	}

	@media screen and (max-width: 820px) {
		flex-flow: column;
	}

	&:hover {
		input {
			border-color: #1b48e0;
		}
	}
`;

const InviteFieldsTitle = styled.p`
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.1px;
	color: #333;
	font-family: ${theme.base_bold_font};
	margin-bottom: 30px;
	@media screen and (max-width: 820px) {
	}
`;

const Field = styled.div`
	width: 360px;
	margin-bottom: 10px;
	> label {
		color: ${theme.descriptions};
		font-size: 12px;
		line-height: 12px;
		font-family: ${theme.base_font};
	}
	input {
		width: 100%;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
	}
`;

const ActionButton = styled.div`
	min-width: 15px;
	min-height: 15px;
	cursor: pointer;
`;

const AddMoreButton = styled(ActionButton)`
	background: url("${plus}") center no-repeat;
`;

const RemoveButton = styled(ActionButton)`
	background: url("${clear}") center no-repeat;
`;

interface IState {
	invites: IInviteEmails[];
}

interface IProps {
	league: IMyLeague;
	league_invites: IInvitesReducer;
	inviteViaEmailClear: typeof inviteViaEmailClear;
	inviteViaEmail: typeof inviteViaEmail;
	min_count_fields: number;
}

class InvitesLeagueEmailComponent extends React.Component<IProps, IState> {
	public state = {
		invites: [],
	};

	constructor(props: any) {
		super(props);
		bindAll(this, ["handleChange", "handleSubmit", "handleAddField", "handleRemoveField", "clearField"]);
	}

	/**
	 * @ignore
	 */
	public get emailFields(): React.ReactNode {
		const {invites} = this.state;
		const {league_invites} = this.props;
		const MIN_LENGTH = 3;

		return (
			<React.Fragment>
				{get(league_invites, "is_data_requested") ? <PagePreloader /> : null}
				<InviteFieldsForm onSubmit={this.handleSubmit}>
					<InviteFieldsTitle>
						{window.getTranslations("competitioninvite_invites_header_email")}:
					</InviteFieldsTitle>

					{invites.map((invite: IInviteEmails, i: number) => (
						<InviteFields key={i}>
							<Field>
								{!i && <label>{window.getTranslations("register_form_label_first_name")} *</label>}

								<Input
									type="text"
									value={invite.first_name}
									name={`first_name`}
									placeholder={window.getTranslations("register_form_label_first_name")}
									onChange={this.handleChange(i)}
									required={!i}
									clearField={partial(this.clearField, "first_name", i)}
								/>
							</Field>

							<Field>
								{!i && <label>{window.getTranslations("register_form_placeholder_email")} *</label>}

								<Input
									type="email"
									value={invite.email}
									name={`email`}
									placeholder={window.getTranslations("register_form_placeholder_email")}
									onChange={this.handleChange(i)}
									required={!i}
									clearField={partial(this.clearField, "email", i)}
									minLength={MIN_LENGTH}
								/>
							</Field>

							{findLastIndex(invites) === i ? (
								<AddMoreButton onClick={this.handleAddField} />
							) : (
								<RemoveButton onClick={partial(this.handleRemoveField, i)} />
							)}
						</InviteFields>
					))}

					{get(league_invites, "is_success") ? (
						<Notify>{window.getTranslations("leagueinvite_form_subheader_sent")}</Notify>
					) : null}

					<SubmitButton type="submit" font_size={window.LANGUAGE === "fr" ? "10px" : "14px"}>
						{window.getTranslations("leagueinvite_form_header_invite")}
					</SubmitButton>
				</InviteFieldsForm>
			</React.Fragment>
		);
	}

	/**
	 * To add one more email's field
	 */
	public handleAddField(e: React.SyntheticEvent<EventTarget>) {
		e.preventDefault();
		const invite: IInviteEmails = {
			email: "",
			first_name: "",
		};
		const {invites = []} = this.state;

		this.setState(
			{
				invites: (invites as IInviteEmails[]).concat(invite),
			},
			this.clear
		);
	}

	/**
	 * To remove one more email's field
	 */
	public handleRemoveField(index: number) {
		const {invites = []} = this.state;

		this.setState(
			{
				invites: [...filter(invites, (item, idx: number) => idx !== index)],
			},
			this.clear
		);
	}

	/**
	 * Record all email addresses in state
	 */
	public handleChange(id: number) {
		return (e: React.SyntheticEvent<EventTarget>) => {
			const target = e.target;
			const value = get(target, "value");
			const name = get(target, "name");
			const {invites: state_invites} = this.state;
			const invites: IInviteEmails[] = (state_invites as IInviteEmails[]).map(
				(invite: any, sidx: number): IInviteEmails => (id === sidx ? {...invite, ...{[name]: value}} : invite)
			);

			this.setState(
				{
					invites,
				},
				this.clear
			);
		};
	}

	public handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
		const {invites} = this.state;
		const result = filter(invites, (invite: IInviteEmails) => !!invite.email);
		if (result.length) {
			const {
				inviteViaEmail: send,
				league: {id},
			} = this.props;

			send({
				league_id: id,
				invites: result,
			});
		}

		if (window._satellite) {
			window._satellite.track("click action", {
				linkModule: "Super Bowl Challenge Actions",
				linkType: "Share",
				linkName: "Invite To Your League",
				siteName: "nfl fantasy intl game",
			});
			console.log("Satellite tracking invite");
		}

		e.currentTarget.reset();
		e.preventDefault();
		this.setDefaultField();
	}

	/**
	 * Pre fill default fields
	 */
	public setDefaultField() {
		const {min_count_fields = 3} = this.props;
		const invite: IInviteEmails = {
			email: "",
			first_name: "",
		};
		const default_invites = [];

		for (let i = 0; i < min_count_fields; i++) {
			default_invites.push(invite);
		}

		this.setState({
			invites: default_invites,
		});
	}

	/**
	 * @ignore
	 */

	public render() {
		return this.emailFields;
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount() {
		this.setDefaultField();
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		this.clear();
	}

	private clear() {
		const {inviteViaEmailClear: clearInvites} = this.props;

		clearInvites();
	}

	private clearField(name: string, index: number) {
		const {invites: state_invites} = this.state;
		const invites: IInviteEmails[] = (state_invites as IInviteEmails[]).map(
			(invite: any, sidx: number): IInviteEmails => (index === sidx ? {...invite, ...{[name]: ""}} : invite)
		);

		this.setState({invites});
	}
}

const mapStateToProps = (state: IStore) => ({
	league_invites: state.league_invites,
	// social_share: state.social_share
});

const mapDispatchToProps = {
	inviteViaEmail,
	inviteViaEmailClear,
};

export const InvitesLeagueEmails = connect(mapStateToProps, mapDispatchToProps)(InvitesLeagueEmailComponent);
export default InvitesLeagueEmails;
