import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {LiveScoreSection} from "modules/types";
import {subscribeToLiveScores, unsubscribeFromLiveScores} from "modules/actions";

// interface ILiveScore {
// 	subscribed_to_live_scores: boolean
// }

interface IOwnProps {
	section: LiveScoreSection;
	children: React.ReactElement;
}

// type State = ILiveScore;

/**
 * Live score subscriber
 */

export const HOCLiveScoreComponent = ({section, children}: IOwnProps) => {
	const dispatch = useDispatch();
	const [subscribe, setSubscribe] = useState(false);

	const subscribeToLiveScore = () => {
		setSubscribe(true);
	};

	const unsubscribeFromLiveScoring = () => {
		setSubscribe(false);
	};

	useEffect(() => {
		subscribeToLiveScore();
		window.addEventListener("online", subscribeToLiveScore);
		window.addEventListener("offline", unsubscribeFromLiveScoring);

		return () => {
			unsubscribeFromLiveScoring();
			window.removeEventListener("online", subscribeToLiveScore);
			window.removeEventListener("offline", unsubscribeFromLiveScoring);
		};
	}, []);

	useEffect(() => {
		if (subscribe) {
			dispatch(subscribeToLiveScores({section}));
		} else {
			dispatch(unsubscribeFromLiveScores());
		}
	}, [subscribe, section, dispatch]);

	return children;
};
