import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import styled from "styled-components";
import theme from "assets/css/theme";
import {RegularButton} from "components";
const Wrapper = styled.div`
	padding: 50px 0;
	text-align: center;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const NotFoundWrapper = styled.div`
	border-top: 8px solid ${theme.primary};
	border-bottom: 8px solid ${theme.primary};
	padding: 10px 0 20px;
	width: 210px;
	margin: 0 auto;
	text-align: center;
	color: ${theme.primary};
	span {
		font-size: 7em;
	}
	p {
		font-size: 1.5em;
	}
`;

const Title = styled.div`
	font-size: 16px;
	text-align: center;
	padding: 1em;
	color: #000;
`;
const TakMeHomeButton = styled(RegularButton)`
	max-width: 200px;
`;

export const NotFound = ({history}: RouteComponentProps) => {
	const gotToHome = () => history.push("/");
	return (
		<React.Fragment>
			<Wrapper>
				<NotFoundWrapper>
					<span>404</span>
					<p>PAGE NOT FOUND</p>
				</NotFoundWrapper>
				<Title>Oops, looks like something went wrong</Title>
				<TakMeHomeButton onClick={gotToHome}>TAKE ME HOME</TakMeHomeButton>
			</Wrapper>
		</React.Fragment>
	);
};

export default withRouter(NotFound);
