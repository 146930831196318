import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {Container} from "components/Container";
import {Betting888LogoWhite} from "components/SkyBetLogo";
import {InputStyle} from "components/Form";
import {RegularButton} from "components/Buttons";
import {useSelector} from "react-redux";
import {getBetMatches} from "modules/selectors/brackets";
import {isEmpty, reduce, isNumber, get} from "lodash";
import {BettingItem} from "components/BettingCalculator/BettingItem";
import {useMediaQuery, useI18n} from "modules/hooks";
import {Exist} from "components/Exist";
import iconInfo from "assets/img/icons/info.svg";
import {isOddsCalculationVisible} from "modules/selectors";
import pokerstarsLogo from "assets/img/pokerstars-logo.svg";
import {SYNDICATE_COUNTRY} from "modules/constants";

const Info = styled.div`
	background: url(${iconInfo}) no-repeat transparent;
	background-size: contain;
	margin: 0;
	padding: 0;
	border: 0;
	appearance: none;
	width: 30px;
	height: 30px;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
`;

interface IInputProps {
	inputmode?: "numeric";
}

const StakeInput = styled(InputStyle)<IInputProps>`
	margin: 0;
	font-size: 14px;
`;

const PlaceBetButton = styled(RegularButton)`
	background: #fc6200;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled.div`
	background: #eee;
	padding: 10px 0 60px;
`;

const Logo = styled(Betting888LogoWhite)`
	margin: 0 auto 10px;
	display: inline-block;

	img {
		height: 28px;
	}
`;

const DeLogo = styled.img`
	width: 200px;
`;

const CalculatorWrapper = styled.section`
	background: #fafafa;
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	border-radius: 4px;
	overflow: hidden;
	font-family: "All-ProSans-Medium", sans-serif;

	p {
		font-size: 10px;
		line-height: 16px;
		margin-bottom: 10px;

		b {
			font-size: 12px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const CalculatorHeader = styled.div`
	background: #fc6200;
	color: #fff;
	padding: 15px;
	text-align: center;
	position: relative;
`;

const CalculatorBody = styled.div`
	padding: 20px 0;
	width: 100%;
	max-width: 582px;
	margin: 0 auto;
`;

const CalculatorForm = styled.form``;

const CalculatorItemList = styled.div`
	width: 100%;
	margin-bottom: 20px;
`;

const CalculatorControls = styled.div`
	width: 100%;
	display: flex;
	max-width: 410px;
	padding: 0 10px;
	margin: 0 auto 10px;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
`;

const ControlsLeft = styled.div`
	width: 48.5%;
	display: flex;
	justify-content: flex-start;
`;

const ControlsRight = styled.div`
	width: 48.5%;
	display: flex;
	justify-content: flex-end;
`;

const StakeLabel = styled.label`
	font-size: 12px;
	color: #757575;
	cursor: pointer;
`;

const StakeValues = styled.div`
	flex: 1;
	font-family: "All-ProSans-Medium", sans-serif;
	color: #333;
	font-size: 12px;

	p {
		font-size: 10px;
		color: #757575;
		margin-bottom: 5px;
		line-height: 14px;
	}

	span {
		display: inline-block;
	}
`;

const Note = styled.small`
	font-size: 8px;
	color: #757575;
	display: block;
	flex: 1;
	text-align: center;
	margin-top: 5px;
`;

const oddsSource = process.env.REACT_APP_ODDS_SOURCE || "";

const getStakeInputValue = (input: HTMLInputElement) => input.value.match(/\d+/g)?.[0] || "";
const getOddsValue = (odds: any) => {
	return reduce(
		odds,
		(acc, item) => {
			const {prediction, home_squad_id, away_squad_id, home_squad_odds, away_squad_odds} = item;
			const home_squad_odds_value_string = get(home_squad_odds, `${oddsSource}.value`, "") as string;
			const away_squad_odds_value_string = get(away_squad_odds, `${oddsSource}.value`, "") as string;

			const home_squad_odds_values = home_squad_odds_value_string.split("/");
			const away_squad_odds_values = away_squad_odds_value_string.split("/");

			const home_squad_odds_value =
				parseInt(home_squad_odds_values[0], 0) / parseInt(home_squad_odds_values[1], 0) + 1;
			const away_squad_odds_value =
				parseInt(away_squad_odds_values[0], 0) / parseInt(away_squad_odds_values[1], 0) + 1;

			const predictionSquadID = get(prediction, "squad_id", 0);
			if (predictionSquadID === home_squad_id && home_squad_odds_value) {
				return home_squad_odds_value * acc;
			}

			if (predictionSquadID === away_squad_id && away_squad_odds_value) {
				return away_squad_odds_value * acc;
			}

			return acc;
		},
		1
	);
};
const getOddsPropIDs = (odds: any) => {
	return odds
		.map((item: any) => {
			const {prediction, home_squad_id, away_squad_id, home_squad_odds, away_squad_odds} = item;
			const predictionSquadID = get(prediction, "squad_id", 0);
			if (predictionSquadID === home_squad_id) {
				return get(home_squad_odds, `${oddsSource}.id`, 0);
			}

			if (predictionSquadID === away_squad_id) {
				return get(away_squad_odds, `${oddsSource}.id`, 0);
			}

			return "";
		})
		.filter((item: string) => item !== "")
		.join(",");
};
const FIXED_NUM = 2;
const getEstimationReturn = (oddsValue: number, stake: number | string) => {
	if (!isNumber(stake)) {
		return "NA";
	}
	const estimated_return = oddsValue * stake;
	return estimated_return === 0 ? "NA" : estimated_return.toFixed(FIXED_NUM);
};

export const BettingCalculator: React.FC = () => {
	const isMobile = useMediaQuery("(max-width: 800px)");
	const betMatches = useSelector(getBetMatches);
	const isCalculatorAllowed = useSelector(isOddsCalculationVisible);
	const language = window.LANGUAGE;

	const title = useI18n("bet_calculator_title");
	const description = useI18n("bet_calculator_description");
	const stake_label = useI18n("bet_calculator_stake_label");
	const place_bet = useI18n("bet_calculator_place_bet");
	const odds_label = useI18n("bet_calculator_odds_label");
	const estimated_return_label = useI18n("bet_calculator_estimated_return_label");
	const gamble_responsibly = useI18n("bet_calculator_gamble_responsibly");

	const [stake, setStake] = useState("10");
	const [prevStake, setPrevStake] = useState(stake);

	const handleChangeStake = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			setStake(getStakeInputValue(event.currentTarget));
		},
		[setStake]
	);

	const handleFocusInputStake = useCallback(() => {
		setPrevStake(stake);
	}, [stake]);

	const handleBlurInputStake = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			if (!getStakeInputValue(event.currentTarget)) {
				setStake(prevStake);
			}
		},
		[prevStake]
	);

	const handleFormSubmit = useCallback((event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();
	}, []);

	if (isEmpty(betMatches) || !isCalculatorAllowed) {
		return null;
	}

	const oddsCalculated = getOddsValue(betMatches);
	const oddsProps: string = getOddsPropIDs(betMatches);
	const estimationReturn = getEstimationReturn(oddsCalculated, parseInt(stake));
	const bettingLink = `https://www.888sport.com/?addAffiliateSelectionId=${oddsProps}`;
	const currencySign = SYNDICATE_COUNTRY === "GB" ? "£" : "€";

	return (
		<Wrapper>
			<Container>
				<CalculatorWrapper>
					<CalculatorHeader>
						{language === "de" ? <DeLogo src={pokerstarsLogo} alt={"Logo"} /> : <Logo />}
						<p>
							<b>{title}</b>
						</p>
						<Exist when={!isMobile}>
							<p>{description}</p>
						</Exist>
						<Info />
					</CalculatorHeader>
					<CalculatorBody>
						<CalculatorItemList>
							{betMatches.map((match) => (
								<BettingItem key={match.bracket_id} match={match} />
							))}
						</CalculatorItemList>
						<CalculatorForm noValidate={true} onSubmit={handleFormSubmit}>
							<CalculatorControls>
								<ControlsLeft>
									<StakeLabel htmlFor="stake">{stake_label}</StakeLabel>
								</ControlsLeft>
							</CalculatorControls>
							<CalculatorControls>
								<ControlsLeft>
									<StakeInput
										onFocus={handleFocusInputStake}
										onBlur={handleBlurInputStake}
										onChange={handleChangeStake}
										id="stake"
										name="stake"
										type="text"
										inputmode="numeric"
										value={`${currencySign}${stake}`}
									/>
								</ControlsLeft>
								<ControlsRight>
									{oddsProps.length ? (
										<PlaceBetButton
											as={"a"}
											href={bettingLink}
											target="_blank"
											rel="noopener noreferrer">
											{place_bet}
										</PlaceBetButton>
									) : (
										<PlaceBetButton disabled={true}>{place_bet}</PlaceBetButton>
									)}
								</ControlsRight>
							</CalculatorControls>
							<CalculatorControls>
								<ControlsLeft>
									<StakeValues>
										<p>{odds_label}</p>
										<span>{oddsCalculated.toFixed(2)}</span>
									</StakeValues>
									<StakeValues>
										<p>{estimated_return_label}</p>
										<span>{estimationReturn}</span>
									</StakeValues>
								</ControlsLeft>
								<ControlsRight>
									<Note>{gamble_responsibly}</Note>
								</ControlsRight>
							</CalculatorControls>
						</CalculatorForm>
					</CalculatorBody>
				</CalculatorWrapper>
			</Container>
		</Wrapper>
	);
};
