import {IApiResponse} from "modules/types";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	// public static USER_EXPIRED_TOKEN = 510;
	public static USER_NOT_REGISTERED = 513;

	public static HAS_ERROR({errors}: IApiResponse) {
		return Boolean(errors && Array.isArray(errors) && errors.length);
	}

	/**
	 * Should be called after each requests when we expect some errors or validations
	 */
	public static CHECK(response: IApiResponse) {
		if (ApiError.HAS_ERROR(response)) {
			const error = response.errors[0];
			throw new ApiError(error.text, error.code);
		}
	}

	public code?: number;
	public text?: string;

	constructor(m: string, code?: number) {
		super(m);
		this.text = m;
		this.name = "API Error";
		this.code = code;
	}
}
