import {RegularButton} from "./";
import styled from "styled-components";
import theme from "assets/css/theme";

export const RegularButtonOutline = styled(RegularButton)`
	border: 1px solid ${theme.primary};
	background-color: #fff;
	color: ${theme.primary};
	max-width: 170px;

	@media screen and (max-width: 840px) {
		font-family: ${theme.base_font};
	}
`;
