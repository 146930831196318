import {ILeagueJoinReducer, ILeaveLeague} from "modules/types";
import {createReducer} from "redux-act";
import {
	clearLeaguesForJoin,
	clearLeaveLeague,
	joinToLeague,
	joinToLeagueFailed,
	joinToLeagueSuccess,
	leaveLeague,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	showLeaguesForJoin,
	showLeaguesForJoinFailed,
	showLeaguesForJoinSuccess,
} from "modules/actions";

const initialState: ILeagueJoinReducer = {
	items: [],
	next: false,
	is_data_requested: false,
	joined_codes: {},
};

export const join_leagues = createReducer({}, initialState)
	.on(showLeaguesForJoin, (state: ILeagueJoinReducer) => {
		return {
			...state,
			is_data_requested: true,
		};
	})
	.on(showLeaguesForJoinSuccess, (state: ILeagueJoinReducer, payload: ILeagueJoinReducer) => {
		return {
			...state,
			items: [...state.items, ...payload.items],
			next: payload.next,
			is_data_requested: false,
		};
	})
	.on(showLeaguesForJoinFailed, (state: ILeagueJoinReducer) => ({
		...state,
		is_data_requested: false,
	}))
	.on(joinToLeague, (state: ILeagueJoinReducer, league_code: string) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[league_code]: {
				is_data_requested: true,
				is_joined: false,
			},
		},
	}))
	.on(joinToLeagueSuccess, (state: ILeagueJoinReducer, payload: string) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload]: {
				is_data_requested: false,
				is_joined: true,
			},
		},
	}))
	.on(joinToLeagueFailed, (state: ILeagueJoinReducer, payload: string) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload]: {
				is_data_requested: false,
				is_joined: false,
			},
		},
	}))
	.on(clearLeaguesForJoin, () => ({
		...initialState,
		joined_codes: {},
	}))
	.on(leaveLeague, (state: ILeagueJoinReducer, payload: ILeaveLeague) => ({
		...state,
		joined_codes: {
			...state.joined_codes,
			[payload.league_code]: {
				is_data_requested: true,
				is_joined: true,
			},
		},
	}))
	.on(leaveLeagueSuccess, (state: ILeagueJoinReducer, payload: ILeaveLeague) => ({
		...state,
		is_success: true,
		joined_codes: {
			...state.joined_codes,
			[payload.league_code]: {
				is_data_requested: false,
				is_joined: false,
			},
		},
	}))
	.on(clearLeaveLeague, (state: ILeagueJoinReducer) => ({
		...initialState,
		is_data_requested: false,
	}))
	.on(leaveLeagueFailed, (state: ILeagueJoinReducer) => ({
		...initialState,
		is_data_requested: false,
	}));
