import {isString, get} from "lodash";
import {SagaIterator} from "redux-saga";
import {put, call, select} from "redux-saga/effects";
import {getGigyaDataSelector, isAuthedSelector} from "modules/selectors";
import {ISagaAction} from "modules/types";
import {User} from "modules/utils/User";
import {
	cleanUpAuth,
	errorsShowGlobalError,
	gigyaLoginAction,
	setGigyaDataAction,
	setGlobalLoaderStateAction,
} from "modules/actions";
import {ApiError} from "modules/utils/Api";

type TPayload = TypeError | Error | string;

const defaultErrorHandler = (payload: TPayload) => {
	if (payload instanceof TypeError) {
		console.error(payload);
		return;
	}

	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const onError = {
		*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
			const isAuthed = yield select(isAuthedSelector);
			let isNeedToReLogin = false;
			const gigyaData = yield select(getGigyaDataSelector);
			window.gigya?.accounts.getAccountInfo({
				callback: (res: unknown) => {
					const errorCode = get(res, "errorCode", 1) as number;
					if (errorCode === 0) {
						isNeedToReLogin = true;
						gigyaData.email = get(res, "profile.email", "") as string;
						gigyaData.gigya_id = get(res, "UID", "") as string;
						gigyaData.gigya_signature = get(res, "UIDSignature", "") as string;
						gigyaData.gigya_signature_timestamp = get(res, "signatureTimestamp", "") as string;
					}
				},
			});
			if (isNeedToReLogin) {
				yield call(setGigyaDataAction, gigyaData);
				yield call(gigyaLoginAction, gigyaData);
				return;
			}

			if (isAuthed) {
				User.CLEAR();
				yield put(cleanUpAuth());
			}

			defaultErrorHandler(error);
		},
	};

	try {
		const error_code = get(payload, "code");
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);

		yield call(onErrorHandler, payload);
	} catch (err) {
		yield put(
			errorsShowGlobalError({
				text: err.message,
			})
		);
		yield put(setGlobalLoaderStateAction(false));
	}
};
