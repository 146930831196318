import styled from "styled-components";
import theme from "assets/css/theme";

export const Label = styled.label`
	color: #757575;
	display: block;
	font-size: 12px;
	padding-bottom: 5px;
	line-height: 20px;
	font-family: ${theme.base_bold_font};
	text-transform: capitalize;

	a {
		text-decoration: underline;
	}
`;
