export const SQUAD_LOGO_URL = process.env.REACT_APP_LOGO || "";
export const CONFIG_COUNTRY = process.env.REACT_APP_COUNTRY || "";
export const SYNDICATE_COUNTRY = process.env.REACT_APP_SYNDICATE_COUNTRY || "";
export const IS_UAT = process.env.REACT_APP_ENVIRONMENT === "uat";

export const IS_PRE_SEASON = process.env.REACT_APP_IS_PRE_SEASON === "true";
export const IS_OFF_SEASON = process.env.REACT_APP_IS_OFF_SEASON === "true";
export const IS_OPEN_PRE_SEASON_REGISTRATION = true;
export const IS_SECRET_ENV = process.env.REACT_APP_IS_ON_SECRET === "true";

export const IS_API_ON_THE_SAME_HOST = (process.env.REACT_APP_API_URL || "").includes(window.location.host);

export const IS_HIDDEN_PRIZES = ["null"].includes(CONFIG_COUNTRY);
