import {IUser} from "modules/types";
import {Storage} from "modules/utils/Storage";
import {DEFAULT_USER, IS_API_ON_THE_SAME_HOST} from "modules/constants";
import {GameSettingsUtils} from "modules/utils/secret";

export abstract class User {
	public static SAVE(user: IUser, session_id?: string): void {
		Storage.SET("user", user);
		Storage.SET("isAuthenticated", "true");

		if (!IS_API_ON_THE_SAME_HOST && typeof session_id === "string") {
			Storage.SET("sid", session_id);
		}
	}

	public static CLEAR(keys: string[] = []): void {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		["user", "isAuthenticated", "sid"].forEach(Storage.REMOVE);
	}

	public static GET(): IUser {
		const storageUser = JSON.parse(Storage.GET("user") || "{}");
		return storageUser.id ? storageUser : DEFAULT_USER;
	}

	public static IS_AUTHENTICATED(): boolean {
		if (!GameSettingsUtils.IS_ENABLED_SESSION) {
			return false;
		}
		return JSON.parse(Storage.GET("isAuthenticated") || "false");
	}

	public static UPDATE_LANG(lang: string) {
		Storage.SET("userLang", lang);
		const user = User.GET();
		if (user.id) {
			Storage.SET("user", {
				...user,
				id: 1,
				predictor: {
					...user.predictor,
					lang: lang,
				},
			});
		}
	}
}
