import * as _ from "lodash";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import {ILadderPayload, ILeagueCreate, ISagaAction} from "modules/types";
import {Api, ApiError} from "modules/utils/Api";

import {getLeagueLadder} from "modules/selectors";
import {
	createLeagueSuccess,
	errorsGlobalError,
	fetchLadderSuccess,
	showLeague,
	updateLeagueFailed,
	updateLeagueSuccess,
} from "modules/actions";

const GAME_NAME = process.env.REACT_APP_NAME || "";

export const createLeagueSaga = function* (action: ISagaAction<ILeagueCreate>): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(Api.League.create, action.payload);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			ApiError.CHECK({errors});
		}

		if (GAME_NAME === "nfl-gb" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(createLeagueSuccess(result.id));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const fetchLadderSaga = function* (action: ISagaAction<ILadderPayload>): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(Api.League.ladder, action.payload);
		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			ApiError.CHECK({errors});
		}

		yield put(fetchLadderSuccess(result));
	} catch (e) {
		console.log(e);
		yield put(errorsGlobalError(e));
	}
};

export const fetchMoreLadderSaga = function* (action: ISagaAction<ILadderPayload>): SagaIterator {
	try {
		const {result, errors, ...status} = yield call(Api.League.ladder, action.payload);
		const ladder = yield select(getLeagueLadder);

		const is_error = !_.isEmpty(errors);

		if (is_error && !status.success) {
			ApiError.CHECK({errors});
		}

		const response = {
			...result,
			ladder: [...ladder, ...result.ladder],
		};

		yield put(fetchLadderSuccess(response));
	} catch (e) {
		console.log(e);
		yield put(errorsGlobalError(e));
	}
};

export const updateLeagueSaga = function* (action: ISagaAction<ILeagueCreate>): SagaIterator {
	try {
		const {result, errors} = yield call(Api.League.update, action.payload);
		ApiError.CHECK({errors});

		if (GAME_NAME === "nfl-gb" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(showLeague(result.id));
		yield put(updateLeagueSuccess(result));
	} catch (e) {
		yield put(updateLeagueFailed());
		yield put(errorsGlobalError(e));
	}
};
