import React, {Fragment} from "react";
import styled from "styled-components";
import {media} from "assets/css/media";
import {useSelector} from "react-redux";
import {isAuthedSelector, getGigyaDataSelector} from "modules/selectors";
import {IMenuItem, MenuItemType} from "modules/types";
import externalIcon from "assets/img/icons/external.png";
import {MenuItemDesktop} from "components/Navigation/items/MenuItemDesktop";
import {SignUp} from "components/Navigation/items/SignUp";
import {GameSettingsUtils} from "modules/utils/secret";

const Wrapper = styled.div`
	display: flex;
	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;
interface IProps {
	menuItems: IMenuItem[];
}

export const DesktopMenu: React.FC<IProps> = ({menuItems}) => {
	const isAuthed = useSelector(isAuthedSelector);
	const userData = useSelector(getGigyaDataSelector);
	const isHideItem = (items: IMenuItem) => (!isAuthed && items.isAuthed) || !items.link;

	const isActiveGame = GameSettingsUtils.IS_ENABLED_SESSION;
	return isActiveGame ? (
		<Wrapper>
			{menuItems.map((item) => {
				return (
					<Fragment key={item.label}>
						{
							{
								[MenuItemType.Link]: !!item.link && (
									<MenuItemDesktop $isHideItem={isHideItem(item)} to={item.link}>
										{window.getTranslations(item.label)}
									</MenuItemDesktop>
								),
								[MenuItemType.External]: !!item.link && (
									<MenuItemDesktop
										as={"a"}
										href={item.link}
										$isHideItem={isHideItem(item)}
										target={"_blank"}
										rel="noopener noreferrer">
										{window.getTranslations(item.label)}
										<img src={externalIcon} alt="External icon" />
									</MenuItemDesktop>
								),
								[MenuItemType.SignUp]: !userData?.gigya_id && <SignUp item={item} />,
							}[item.menuItemType]
						}
					</Fragment>
				);
			})}
		</Wrapper>
	) : null;
};
