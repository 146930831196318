import * as React from "react";
import styled from "styled-components";
import {IconSpinner} from "components/Icons/IconSpinner";

const Wrapper = styled.div`
	background: #101251;
	opacity: 0.7;
	z-index: 10;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 30px;
`;

export const PagePreloader: React.FC = () => (
	<Wrapper>
		<IconSpinner />
	</Wrapper>
);
