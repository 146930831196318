import {createAction} from "redux-act";
import {IFetchTeamStatistics, IRoundReducer, ISquadReducer, ITeamStatistics} from "modules/types";

export const fetchRoundsAction = createAction();
export const fetchRoundSuccessAction = createAction<IRoundReducer>();

export const fetchSquadsAction = createAction();
export const fetchSquadsSuccessAction = createAction<ISquadReducer>();

export const fetchTeamStatistics = createAction<IFetchTeamStatistics>();
export const fetchTeamStatisticsSuccess = createAction<ITeamStatistics>();
export const clearTeamStatistics = createAction();
