import {createReducer} from "redux-act";
import {fetchChecksumsJSONSuccess} from "modules/actions";
import {IDictionary} from "modules/types";

const defaultState: IDictionary<unknown> = {};

export const checksums = createReducer<typeof defaultState>({}, defaultState).on(
	fetchChecksumsJSONSuccess,
	(_state, payload) => payload
);
