import {createReducer} from "redux-act";
import {fetchSharingImgUrl, fetchSharingImgUrlSuccess} from "modules/actions";

interface IShareUrl {
	url: string | null;
}

const defaultState: IShareUrl = {
	url: null,
};

export const share = createReducer<IShareUrl>({}, defaultState);
share.on(fetchSharingImgUrl, (state) => ({
	...state,
	url: null,
}));
share.on(fetchSharingImgUrlSuccess, (state, payload) => ({
	...state,
	url: payload,
}));
