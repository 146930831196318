import React from "react";
import styled from "styled-components";
import {ITeamStatistics} from "modules/types";
import {useSelector} from "react-redux";
import {getSquadById} from "modules/selectors";
import theme from "assets/css/theme";
import {get} from "lodash";

const OpponentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
`;

interface IOpponentBlock {
	align: string;
}

const OpponentBlock = styled.div<IOpponentBlock>`
	font-size: 16px;
	text-transform: uppercase;
	text-align: ${({align}) => align};
`;

const FullName = styled.p`
	font-size: 12px;
`;

const ShortName = styled.p`
	font-family: ${theme.mediumSecondFont};
	letter-spacing: 0;
	line-height: 20px;
	font-size: 20px;
	margin: 3px 0;
`;

const WdlBlock = styled.div`
	color: #cccccc;
	font-family: ${theme.mediumSecondFont};
	font-size: 24px;
`;

interface IProps {
	statistics: ITeamStatistics;
}

export const TeamsWdl: React.FC<IProps> = (props) => {
	const {statistics} = props;
	const {season_record, home_squad_id, away_squad_id} = statistics;
	const home_squad = useSelector(getSquadById)(home_squad_id);
	const away_squad = useSelector(getSquadById)(away_squad_id);
	const home_record = get(season_record, home_squad_id, []);
	const home_wdl = {
		win: home_record.find((record) => record.wdl === "win")?.count || 0,
		los: home_record.find((record) => record.wdl === "loss")?.count || 0,
	};

	const away_record = get(season_record, away_squad_id, []);
	const away_wdl = {
		win: away_record.find((record) => record.wdl === "win")?.count || 0,
		los: away_record.find((record) => record.wdl === "loss")?.count || 0,
	};

	const FullNameHome = home_squad?.full_name.split(" ");
	const ShortNameHome = FullNameHome?.pop();

	const FullNameAway = away_squad?.full_name.split(" ");
	const ShortNameAway = FullNameAway?.pop();
	return (
		<OpponentWrapper>
			<OpponentBlock align={"left"}>
				<FullName>{FullNameHome?.join(" ")}</FullName>
				<ShortName>{ShortNameHome}</ShortName>
				<WdlBlock>
					{home_wdl.win}-{home_wdl.los}
				</WdlBlock>
			</OpponentBlock>
			<OpponentBlock align={"right"}>
				<FullName>{FullNameAway?.join(" ")}</FullName>
				<ShortName>{ShortNameAway}</ShortName>
				<WdlBlock>
					{away_wdl.win}-{away_wdl.los}
				</WdlBlock>
			</OpponentBlock>
		</OpponentWrapper>
	);
};
