import React from "react";
import {MenuItemDesktop} from "components/Navigation/items/MenuItemDesktop";
import {IMenuItem} from "modules/types";
import {SSOUtils} from "modules/utils/sso";
import {NavItemMobile, WrapperMobile} from "./MenuItemMobile";
import {useDispatch} from "react-redux";
import {errorsGlobalError} from "modules/actions";

interface IProps {
	item: IMenuItem;
	isMobile?: true;
}

export const SignUp: React.FC<IProps> = ({item, isMobile}) => {
	const dispatch = useDispatch();

	const onClick = () => {
		try {
			SSOUtils.LOGIN();
		} catch (e) {
			dispatch(errorsGlobalError(e));
		}
	};

	if (isMobile) {
		return (
			<WrapperMobile isHideItem={false}>
				<NavItemMobile as="button" onClick={onClick}>
					{window.getTranslations(item.label)}
				</NavItemMobile>
			</WrapperMobile>
		);
	}

	return (
		<MenuItemDesktop as={"button"} onClick={onClick}>
			{window.getTranslations(item.label)}
		</MenuItemDesktop>
	);
};
