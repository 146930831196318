import React, {useState} from "react";
import styled from "styled-components";
import bg from "assets/img/landing-background.jpg";
import {RegularButton} from "components/Buttons";
import {Container} from "components/Container";
import theme from "assets/css/theme";
import logo from "assets/img/logo-tall.svg";
import syndicateLogo from "assets/img/01. Logo RedeTV_Official.png";
import deLogo from "assets/img/de-logo-tall.svg";
import {eq} from "lodash";
import {useSelector} from "react-redux";
import {getIsNeedToRegisterSelector} from "modules/selectors";
import {getOmnitureTracking, createConnextraScriptTag} from "modules/utils";
import {Redirect, useLocation} from "react-router-dom";
import {Exist, PagePreloader, WarningConnectWithSSOModal} from "components";
import {SYNDICATE_COUNTRY} from "modules/constants";
import {SSOUtils} from "modules/utils/sso";
import {useSecretEnv} from "modules/hooks";

const Wrapper = styled.div`
	background: url(${bg}) center no-repeat;
	background-size: cover;
	flex: 1;
	min-height: 90vh;
`;
const InnerWrapper = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 120px 0;
`;
const FormWrapper = styled.div`
	padding: 0 20px 50px;
	background: #ffffff;
	max-width: 340px;
	display: flex;
	align-items: center;
	flex-direction: column;
`;
const Title = styled.div`
	margin: 20px 0;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	font-family: ${theme.base_bold_font};
`;
const Logo = styled.img`
	margin-top: -91px;
	width: 259px;
`;
const SponsorLogo = styled.img`
	margin-top: 10px;
	max-width: 150px;
`;

const SignInText = styled.div`
	color: #151515;
	font-size: 14px;
	a {
		text-decoration: underline;
	}
`;
const SignUpButton = styled(RegularButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 37px;
	max-width: 203px;
`;

const SignInButton = styled.span`
	background: none;
	border: none;
	padding: 0;
	color: #151515;
	text-decoration: underline;
	cursor: pointer;
`;

const isPreSeason = process.env.REACT_APP_IS_PRE_SEASON === "true";
const isOffSeason = process.env.REACT_APP_IS_OFF_SEASON === "true";

const checkOnSeason = (isPreSeason: boolean, isOffSeason: boolean, isOnSecret: boolean) =>
	(!isPreSeason && !isOffSeason) || isOnSecret;

export const Login = () => {
	const isNeedToRegister = useSelector(getIsNeedToRegisterSelector);
	const {isUnderSecret, onSecretClick} = useSecretEnv();
	const search = useLocation().search;
	const isGigActions = new URLSearchParams(search).get("gig_actions");
	getOmnitureTracking("home", "landing", {});
	const [isShowSSOWarning, setSSOWarningState] = useState(false);

	if (isNeedToRegister) {
		return <Redirect to={"/register"} />;
	}

	createConnextraScriptTag(
		"https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_homepage"
	);

	const headerLogo = process.env.REACT_APP_NAME === "nfl-de" ? deLogo : logo;
	const handleSSO = () => {
		const result = SSOUtils.LOGIN();
		setSSOWarningState(!result);
	};

	if (isGigActions) {
		return (
			<Wrapper>
				<InnerWrapper>
					<PagePreloader />
				</InnerWrapper>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<InnerWrapper>
				<FormWrapper>
					<Logo src={headerLogo} alt={"NFL logo"} onClick={onSecretClick} />

					<Exist when={eq(SYNDICATE_COUNTRY, "BR")}>
						<a href={"https://www.redetv.uol.com.br/"} target={"_blank"} rel={"noreferrer"}>
							<SponsorLogo src={syndicateLogo} alt={"Sponsor logo"} />
						</a>
					</Exist>

					{isPreSeason ? (
						<Title>{window.getTranslations("coming_soon.pre_season.message")}</Title>
					) : isOffSeason ? (
						<Title>{window.getTranslations("landing.modal.thanks_for_playing")}</Title>
					) : null}

					{checkOnSeason(isPreSeason, isOffSeason, !isUnderSecret) ? (
						<React.Fragment>
							<Title>{window.getTranslations("register_text_welcome_text_register")}</Title>
							<SignUpButton as={"a"} onClick={handleSSO}>
								{window.getTranslations("register_button_register_signup_button")}
							</SignUpButton>
							<SignInText>
								{window.getTranslations("landing_homepage_link_signin_here")}{" "}
								<SignInButton onClick={handleSSO} tabIndex={0}>
									{window.getTranslations("register_button_signup_existing_button")}
								</SignInButton>
							</SignInText>
						</React.Fragment>
					) : null}
					<WarningConnectWithSSOModal isShow={isShowSSOWarning} />
				</FormWrapper>
			</InnerWrapper>
		</Wrapper>
	);
};
