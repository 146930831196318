import {IGigyaLoginParams, IRegisterAction, ISagaAction, IUpdateUserAction} from "modules/types";
import {call, put, select} from "redux-saga/effects";
import {ApiError} from "modules/utils/Api/ApiError";
import {User} from "modules/utils/User";
import {Api} from "modules/utils/Api";
import {authedAction, setIsNeedToRegisterAction} from "modules/actions/auth";
import {getGigyaDataSelector, getUser} from "modules/selectors";
import {errorsGlobalError, setGlobalLoaderStateAction} from "modules/actions/global";
import {get} from "lodash";
import {createConnextraScriptTag} from "modules/utils";
import {GameSettingsUtils} from "modules/utils/secret";

export const gigyaLoginSaga = function* ({payload}: ISagaAction<IGigyaLoginParams>) {
	if (!GameSettingsUtils.IS_ENABLED_SESSION) {
		return;
	}
	try {
		const response = yield call(Api.Auth.gigyaLogin, payload);
		ApiError.CHECK(response);
		const {user, session_id} = response.result;
		User.SAVE(user, session_id);
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_loggedin?AccountID${
				user.id as number
			}`
		);
		yield put(authedAction(user));
	} catch (e) {
		if (e.code === 404) {
			yield put(setIsNeedToRegisterAction(true));
		} else {
			yield put(errorsGlobalError(e));
		}
	}
};

export const registerSaga = function* ({payload}: ISagaAction<IRegisterAction>) {
	const gigyaData = yield select(getGigyaDataSelector);
	try {
		const response = yield call(Api.Auth.registerForGame, {...gigyaData, ...payload});
		ApiError.CHECK(response);

		const {user, session_id} = response.result;
		User.SAVE(user, session_id);
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_regconfirm?AccountID=${
				user.id as number
			}`
		);
		yield put(setIsNeedToRegisterAction(false));
		yield put(authedAction(user));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const logoutSaga = function* () {
	try {
		yield call(Api.Auth.logout);
		User.CLEAR();
		yield put(setGlobalLoaderStateAction(false));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const backdoorSaga = function* ({payload}: ISagaAction<string>) {
	try {
		const response = yield call(Api.Auth.gigyaBackdoor, {email: payload});
		ApiError.CHECK(response);

		const {user, session_id} = response.result;
		User.SAVE(user, session_id);

		yield put(authedAction(user));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const getSessionSaga = function* () {
	try {
		if (!GameSettingsUtils.IS_ENABLED_SESSION) {
			return;
		}
		const response = yield call(Api.Auth.userSession);
		ApiError.CHECK(response);

		const {user, session_id} = response.result;
		User.SAVE(user, session_id);

		yield put(authedAction(user));
	} catch (e) {
		// Do not show any errors as it's expected
	}
};

export const updateAccountSaga = function* ({payload}: ISagaAction<IUpdateUserAction>) {
	try {
		const response = yield call(Api.Auth.updateUser, payload);
		ApiError.CHECK(response);

		const {user, session_id} = response.result;
		User.SAVE(
			{
				...user,
				recovered: 0,
			},
			session_id
		);

		yield put(
			authedAction({
				...user,
				recovered: 0,
			})
		);

		// Reload page on change language
		if (get(payload, "lang", null)) {
			document.location.reload();
		}
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};

export const deactivateUserSaga = function* () {
	try {
		const response = yield call(Api.Auth.deactivate);
		ApiError.CHECK(response);
		User.CLEAR();
	} catch (e) {
		console.log(e);
	}
};

export const recoverUserSaga = function* () {
	try {
		const response = yield call(Api.Auth.recover);
		ApiError.CHECK(response);

		const {user, session_id} = response.result;
		User.SAVE(user, session_id);

		yield put(authedAction(user));
	} catch (e) {
		const user = yield select(getUser);

		if (e.code === 409) {
			const recoveredUser = {
				...user,
				recovered: 1,
			};

			User.SAVE(recoveredUser);
			yield put(authedAction(recoveredUser));
			return;
		}

		yield put(errorsGlobalError(e));
	}
};
