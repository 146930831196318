import {createReducer, Reducer} from "redux-act";
import {IDictionary, IPrediction} from "modules/types";
import {cleanUpPicksAction, fetchPredictionsSuccessAction, savePredictionsListSuccessAction} from "modules/actions";

const initialState: IDictionary<IPrediction> = {};
export const predictions: Reducer<IDictionary<IPrediction>> = createReducer({}, initialState);
predictions.on(cleanUpPicksAction, (state, payload) => ({
	...payload,
	gameBar: state.gameBar,
}));
predictions.on(savePredictionsListSuccessAction, (state, payload) => payload);
predictions.on(fetchPredictionsSuccessAction, (state, payload) => payload);
