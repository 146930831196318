import styled from "styled-components";

interface ILeagueWrapper {
	shadow?: boolean;
	background?: string;
}

export const LeagueWrapper = styled.div<ILeagueWrapper>`
	background: ${({background}) => (background ? background : "#fff")};
	border-radius: 4px;
	padding: 20px;
	width: 100%;
	max-width: 386px;
	box-sizing: border-box;
	box-shadow: ${({shadow}) => (shadow ? "0 0 10px 0 #E0E0E0;" : "none")};

	@media screen and (max-width: 820px) {
		max-width: 100%;
		background: #fff;
		padding: 0;
	}
`;
export const CreateLeagueWrapper = styled(LeagueWrapper)`
	@media screen and (max-width: 820px) {
		padding: 28px 17px;
	}
`;
