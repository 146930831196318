import theme from "assets/css/theme";

import {LeagueAboutLink, RegularButtonOutline, ShareLeague} from "components";
import {bindAll, get, includes, partial} from "lodash";
import {sendIncrementQuery, showLeague} from "modules/actions";
import {EnumLeagueClass, ShareType} from "modules/constants/enums";
import {getMyLeague, getUser} from "modules/selectors";
import {IStore} from "modules/types";
import {copyToClipboard, delay, share, getOmnitureTracking} from "modules/utils";
import React, {Fragment, RefObject} from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import InvitesLeagueEmails from "./invitesViaEmails";

interface IInviteLeagueWrapper {
	shadow?: boolean;
}

const InviteLeagueWrapper = styled.div<IInviteLeagueWrapper>`
	padding: 20px 30px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow: ${({shadow}) => (shadow ? "0 0 10px 0 #E0E0E0" : "none")};
	border-radius: 4px;

	@media screen and (max-width: 820px) {
		width: 100%;
	}
`;

const InvitesTitle = styled.div`
	font-family: ${theme.base_bold_font};
	font-size: 28px;
	line-height: 34px;
	color: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 35px;

	@media screen and (max-width: 820px) {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 10px;
	}
`;

const InvitesDescription = styled.div`
	font-family: ${theme.base_font};
	font-size: 14px;
	line-height: 22px;
	color: #555;
	> p {
		font-family: ${theme.base_bold_font};
	}
	margin-bottom: 30px;
`;

const SkipMobileText = styled.span``;

const SkipDesktopText = styled.span``;

const SkipButton = styled.div`
	color: #757575;
	font-family: ${theme.base_font};
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
	line-height: 22px;
	${SkipMobileText} {
		display: none;
	}

	@media screen and (max-width: 820px) {
		${SkipDesktopText} {
			display: none;
		}
	}

	@media screen and (max-width: 820px) {
		${SkipMobileText} {
			display: block;
		}
	}
`;

interface ICopyButton {
	copied: boolean;
}

const CopyButton = styled(RegularButtonOutline)<ICopyButton>`
	transition: all 250ms ease-in-out;
	margin-bottom: 25px;
	font-family: ${theme.base_bold_font};

	${({copied}) =>
		copied &&
		css`
			background-color: ${theme.primary};
			color: #fff;
		`};

	@media screen and (max-width: 820px) {
		max-width: 100%;
	}
`;

interface IOwn {
	league_created_id?: number | null;
	league_id?: number;
	shadow?: boolean;
	clear?: () => void;
	is_create?: boolean;
	parrentRef?: RefObject<HTMLDivElement>;
}

interface IState {
	copied_link: boolean;
	copied_code: boolean;
}

const mapStateToProps = (state: IStore) => ({
	league: getMyLeague(state),
	user: getUser(state),
	omniture_tracking: getOmnitureTracking,
});

const mapDispatchToProps = {
	showLeague,
	sendIncrementQuery,
};

type TProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & IOwn;

class InvitesLeagueComponent extends React.Component<TProps, IState> {
	public state = {
		copied_link: false,
		copied_code: false,
	};

	constructor(props: TProps) {
		super(props);

		bindAll(this, ["copyLink", "copyCode", "handleShare"]);
	}

	/**
	 * Hide message for copy button
	 */
	public hideMessage() {
		const THREE_SECONDS = 1000;
		delay(THREE_SECONDS).then(() => {
			this.setState({
				copied_link: false,
				copied_code: false,
			});
		});
	}

	/**
	 * Copy league code
	 */
	public copyCode(clipboard_text: string) {
		copyToClipboard(clipboard_text).then(() => {
			this.setState(
				{
					copied_code: true,
				},
				this.hideMessage
			);
		});
		if (window._satellite) {
			window._satellite.track("click action", {
				linkModule: "Super Bowl Challenge Actions",
				linkType: "Share",
				linkName: "Copy Code",
				siteName: "nfl fantasy intl game",
			});
			console.log("Satellite tracking copy code");
		}
	}

	/**
	 * Copy league code
	 */
	public copyLink(clipboard_text: string) {
		copyToClipboard(clipboard_text).then(() => {
			this.setState(
				{
					copied_link: true,
				},
				this.hideMessage
			);
		});
	}

	/**
	 * Method for sharing Twitter and Facebook
	 */
	public handleShare(type_share: ShareType, comp_code: string) {
		const {league_id, league_created_id, sendIncrementQuery: sendIncrement} = this.props;
		const translation = window.getTranslations("competitioninvite_invites_text_twitter") || "";
		const message: string = `${translation} ${comp_code}.`;

		sendIncrement({
			type: "league",
			social: type_share,
		});

		share({
			sc: type_share,
			league_id: league_created_id || league_id,
			section: "league",
			message,
		});
		if (window._satellite) {
			window._satellite.track("click action", {
				linkModule: "Super Bowl Challenge Actions",
				linkType: "Share",
				linkName: `Invite To Via Social - ${type_share}`,
				siteName: "nfl fantasy intl game",
			});
			console.log("Satellite tracking invite");
		}
	}

	/**
	 * Fetch league by id
	 */
	public componentDidMount() {
		const {showLeague: show, league_created_id, league_id} = this.props;

		if (league_created_id) {
			show(league_created_id);
		} else if (league_id) {
			show(league_id);
		}
	}

	/**
	 * Fetch league by id
	 */
	public componentDidUpdate(prevProps: TProps) {
		const {showLeague: show, league_created_id} = this.props;

		if (league_created_id && league_created_id !== prevProps.league_created_id) {
			show(league_created_id);
		}
	}

	public hideParentRef() {
		const {parrentRef} = this.props;

		if (parrentRef && parrentRef.current) {
			parrentRef.current.classList.add("hidden");
		}
	}

	public isLocked() {
		const {league, is_create} = this.props;
		const league_class = get(league, "class");
		if (!league.id && !is_create) {
			return true;
		}
		return includes([EnumLeagueClass.Overall, EnumLeagueClass.Club, EnumLeagueClass.Region], league_class);
	}
	/**
	 * @ignore
	 */
	public render() {
		const {copied_code, copied_link} = this.state;
		const {league, league_created_id, shadow, omniture_tracking, clear, is_create, user} = this.props;

		if (this.isLocked()) {
			this.hideParentRef();
			return null;
		}

		const code = get(league, "code") || "Unknown";
		const link = `${window.location.origin}/leagues/join/${league.code}`;
		omniture_tracking("leagues", "create league:invite", user);

		return (
			<InviteLeagueWrapper shadow={shadow}>
				<InvitesTitle>
					{window.getTranslations("leaguecreate_form_text_invite")}
					{league_created_id ? (
						<SkipButton onClick={clear} as={LeagueAboutLink(league_created_id)} to={"/"}>
							<SkipMobileText>{window.getTranslations("leagueinvite_form_invite_skip")}</SkipMobileText>
							<SkipDesktopText>
								{window.getTranslations("leagueinvite_form_invite_skip_now")}
							</SkipDesktopText>
						</SkipButton>
					) : null}
				</InvitesTitle>
				<InvitesDescription>
					{window.getTranslations("leagueinvite_invites_text_invite_friends")}
					<p>
						{window.getTranslations("leagueinvite_invites_text_code", [{League: league.name}])} {code}
					</p>
				</InvitesDescription>

				<CopyButton copied={copied_code} onClick={partial(this.copyCode, code)}>
					{copied_code
						? window.getTranslations("leagueinvite_form_button_copied")
						: window.getTranslations("leagueinvite_invites_text_copy_code")}
				</CopyButton>

				{league.user_id === user.id || is_create ? (
					<Fragment>
						<div className={"hidden-mobile"}>
							<InvitesLeagueEmails league={league} min_count_fields={3} />
						</div>
						<div className={"hidden-desktop"}>
							<InvitesLeagueEmails league={league} min_count_fields={1} />
						</div>
					</Fragment>
				) : null}

				<ShareLeague
					comp_code={code}
					copied_link={copied_link}
					onCopy={partial(this.copyLink, link)}
					onShare={this.handleShare}
					title={window.getTranslations("leagueinvite_invites_header_email")}
				/>
			</InviteLeagueWrapper>
		);
	}
}
export const InvitesLeague = connect(mapStateToProps, mapDispatchToProps)(InvitesLeagueComponent);
