import {get, size, map, trim, isFunction, head, compact} from "lodash";
import {IDictionary} from "modules/types";

const required = (value: string) => !value;

interface IValidateOptions {
	formValues: IDictionary<string | number>;
	title: string;
}

type TValidation = (value: string, options: IValidateOptions) => string | false;
const minFunction = (value: string, min: number) => {
	if (size(value) < min) {
		return `Min ${min} Characters`;
	}
	return false;
};
const maxFunction = (value: string, max: number) => {
	if (size(value) > max) {
		return `Max ${max} Characters`;
	}
	return false;
};
export const validations: IDictionary<TValidation> = {
	required: (value: string, options: IValidateOptions) => {
		if (required(value)) {
			return window.getTranslations("register_validation_required");
		}
		return false;
	},
	checkedOnly: (value: string) => {
		if (!value) {
			return window.getTranslations("register_validation_required");
		}
		return false;
	},
	onlyLetters: (value: string, options: IValidateOptions) => {
		const regrxp = /^[a-zA-Z0-9\s-]{2,15}$/;
		const test = regrxp.exec(value);
		if (!test) {
			return `${options.title} only letters`;
		}
		return false;
	},
	min2: (value: string) => {
		return minFunction(value, 2);
	},
	max40: (value: string) => {
		return maxFunction(value, 40);
	},
};

export const valueOfElement = (target: Element) => {
	const is_checkbox = get(target, "type") === "checkbox";
	const checkboxValue = get(target, "checked", 0) ? 1 : 0;
	return is_checkbox ? checkboxValue : get(target, "value", "");
};

// eslint-disable-next-line complexity
export const validateField = (e: HTMLFormElement, formValues: IDictionary<string | number>) => {
	const value = valueOfElement(e);
	const name = e.getAttribute("name") || "";
	const title = e.getAttribute("data-required") || e.getAttribute("title") || "";
	const validate_options = {
		formValues,
		title,
		name,
	};
	if (!name) {
		return false;
	}
	const element_validations = e.getAttribute("data-validations") || "";
	const functions = [...element_validations.split(",")];
	if (size(functions)) {
		const errors = map(functions, (f: string) => {
			const func = validations[trim(f)];
			return isFunction(func) && func(value, validate_options);
		});
		const top_error = head(compact(errors));
		if (top_error) {
			return {
				name,
				error: top_error,
			};
		}
	}
	return false;
};
