import {createReducer, Reducer} from "redux-act";
import {IGlobalReducers} from "modules/types/reducers";
import {
	fetchCountrySuccessAction,
	setGlobalLoaderStateAction,
	setHighlightVideoAction,
	setHowToPlayModalStateAction,
	setRePickModalState,
	setRoundsFetchedStateAction,
} from "modules/actions/global";

const initialState: IGlobalReducers = {
	isGigyaReady: false,
	isGlobalPreloader: false,
	isRoundsFetched: false,
	country: "",
	highLightVideo: "",
	showRePickModal: false,
	showHowToPlayModal: false,
};
export const global: Reducer<IGlobalReducers> = createReducer({}, initialState);
global.on(setGlobalLoaderStateAction, (state, payload) => ({
	...state,
	isGlobalPreloader: payload,
}));
global.on(setRoundsFetchedStateAction, (state, payload) => ({
	...state,
	isRoundsFetched: payload,
}));
global.on(fetchCountrySuccessAction, (state, payload) => ({
	...state,
	country: payload,
}));
global.on(setHighlightVideoAction, (state, payload) => ({
	...state,
	highLightVideo: payload,
}));
global.on(setRePickModalState, (state, payload) => ({
	...state,
	showRePickModal: payload,
}));
global.on(setHowToPlayModalStateAction, (state, payload) => ({
	...state,
	showHowToPlayModal: payload,
}));
