import {get} from "lodash";

export const toggleFixedBody = (state: boolean) => {
	if (!state) {
		document.body.classList.remove("fixed-scroll");
	} else {
		document.body.classList.add("fixed-scroll");
	}
};

export const moveToBracket = (bracketID: number) => {
	const bracket = document.getElementById(`swipe_bracket_${bracketID}`);
	const bracketsListEl = document.getElementById("brackets-list");
	if (bracketsListEl && bracketID === 1) {
		bracketsListEl.style.left = `0px`;
	}
	if (bracket && bracketsListEl) {
		const offsetWidth = get(bracket, "offsetLeft", 0);
		bracketsListEl.style.left = `-${offsetWidth - 28}px`;
	}
};

export * from "./validation";
