import {call, put} from "redux-saga/effects";
import {ApiError} from "modules/utils/Api/ApiError";
import {Api} from "modules/utils/Api";
import {errorsGlobalError, fetchCountrySuccessAction} from "modules/actions";

export const fetchCountrySaga = function* () {
	try {
		const response = yield call(Api.Global.country);
		ApiError.CHECK(response);
		yield put(fetchCountrySuccessAction(response.result));
	} catch (e) {
		yield put(errorsGlobalError(e));
	}
};
