import {compact, groupBy, keyBy, orderBy, reduce} from "lodash";
import {WILD_CARD_ROUND_ID} from "modules/constants";
import {getRoundsGTEWildcard} from "modules/selectors/rounds";
import {IRound, ISquad, IStore, TResultPerRoundFansPredictions} from "modules/types";
import {createSelector} from "reselect";

export const getSquads = ({squads}: IStore) => squads;
export const getSquadById = createSelector(
	getSquads,
	(squads) => (squad_id: string) => squads.find((squad) => squad.id === squad_id)
);

export const getSquadsByID = createSelector(getSquads, (squads) => keyBy(squads, "id"));

export const getSquadByIDSelector = createSelector(
	getSquadsByID,
	(squadsByID) =>
		(squadID: string): ISquad | undefined =>
			squadsByID[squadID]
);

const mergedWithRounds = (squads: ISquad[], rounds: IRound[]) => {
	const resultPerRounds: Record<string, TResultPerRoundFansPredictions[]> = {};

	rounds.forEach((round) => {
		const fanPredictionKey = round.id - WILD_CARD_ROUND_ID + 1;
		const squadsRound = squads.map((singleSquad) => {
			const predictions = singleSquad?.stats?.fan_predictions[fanPredictionKey];
			const isNeedToSkipSquadForWildcard = singleSquad.seed === 1 && fanPredictionKey === 1;
			const isSquadDoesntPlay = !singleSquad.seed;

			if (!predictions || isSquadDoesntPlay || isNeedToSkipSquadForWildcard) {
				return undefined;
			}

			return {
				...predictions,
				...singleSquad,
				originRoundID: round.id,
			};
		});

		resultPerRounds[fanPredictionKey] = orderBy(compact(squadsRound), "percent", ["desc"]);
	});

	return resultPerRounds;
};

export const getFansPredictions = createSelector([getSquads, getRoundsGTEWildcard], (squads, rounds) => {
	if (!rounds.length) {
		return undefined;
	}

	const groupByConference = groupBy(squads, "conference");

	return reduce<
		typeof groupByConference,
		Partial<Record<ISquad["conference"], Record<string, TResultPerRoundFansPredictions[]>>>
	>(
		groupByConference,
		(acc, singleConf, key) => {
			return {
				...acc,
				[key]: mergedWithRounds(singleConf, rounds),
			};
		},
		{}
	);
});
