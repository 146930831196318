import {get} from "lodash";

export abstract class SSOUtils {
	static LOGIN() {
		const isAllowedToLogin = get(window, "gigya.sso", false);

		if (isAllowedToLogin) {
			window.gigya?.sso.login({
				authFlow: "redirect",
				context: {
					apiKey: window.gigya.apiKey,
				},
			});

			return true;
		}

		return false;
	}
}
