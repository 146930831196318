// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import NotFound from "pages/NotFound";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {PagePreloader} from "components/Preloader";
import {Provider} from "react-redux";
import {store} from "modules/store";
import {GigyaGate} from "components/GigyaGate";
import {NotAuthOnlyRoute, PrivateRoute} from "components/Route";
import {Translations} from "components/Translations";
import {GlobalPreloader} from "components/GlobalPreloader";
import {ComingSoonModal, CountriesRedirect, HowToPlayModal, VideoPlayerModal} from "components";
import {GlobalErrors} from "components/GlobalErrors";
import {Login} from "pages/Login";
import {IS_UAT} from "modules/constants";
import {GameSettingsUtils} from "modules/utils/secret";
import {RegisterModal} from "components/RegisterModal";
import {ConfirmSaveModal} from "components/ConfirmSaveModal";
import {RecoverModal} from "components/RecoverModal";

const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || "";

type Factory<T> = () => Promise<{
	default: React.ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

const ProjectIndex = lazy(retryFailLoad(() => import(`apps/${process.env.REACT_APP_PROJECT_NAME || ""}`)));
const Backdoor = lazy(retryFailLoad(() => import("pages/Backdoor")));
const Landing = lazy(retryFailLoad(() => import("pages/Landing")));
const Brackets = lazy(retryFailLoad(() => import("pages/Brackets")));
const FanPredictions = lazy(retryFailLoad(() => import("pages/FanPredictions")));
const SharedBrackets = lazy(retryFailLoad(() => import("pages/SharedBrackets")));

const Leagues = lazy(retryFailLoad(() => import("pages/Leagues")));
const Ladder = lazy(retryFailLoad(() => import("pages/league/Ladder")));
const About = lazy(retryFailLoad(() => import("pages/league/About")));
const CreateLeague = lazy(retryFailLoad(() => import("pages/Leagues/CreateLeague")));
const JoinLeague = lazy(retryFailLoad(() => import("pages/Leagues/JoinLeague")));

const Prizes = lazy(retryFailLoad(() => import("pages/Prizes")));

const Help = lazy(retryFailLoad(() => import("./pages/Help")));
const GameGuidelines = lazy(retryFailLoad(() => import("./pages/Help/GameGuidelines")));
const ContactUs = lazy(retryFailLoad(() => import("./pages/Help/ContactUs")));
const FAQs = lazy(retryFailLoad(() => import("./pages/Help/FAQs")));
const GameRules = lazy(retryFailLoad(() => import("./pages/Help/GameRules")));
const PrizesPool = lazy(retryFailLoad(() => import("./pages/Help/PrizesPool")));
const Terms = lazy(retryFailLoad(() => import("./pages/Help/Terms")));
// const PrivacyPolicy = lazy(() => import("./pages/Help/PrivacyPolicy"));

const YourAccount = lazy(retryFailLoad(() => import("./pages/YourAccount")));
const Communications = lazy(retryFailLoad(() => import("./pages/yourAccount/Communications")));
const NFLAccount = lazy(retryFailLoad(() => import("./pages/yourAccount/NFLAccount")));
const DeactivateAccount = lazy(retryFailLoad(() => import("./pages/yourAccount/DeactivateAccount")));

let errorCount = 0;
const MAX_ERRORS = 100;
const IGNORE_ERRORS = [
	"Authentication Required",
	"Load failed",
	"UET is not defined",
	"Loading chunk",
	"AbortError: Share canceled",
	"Failed to fetch",
	"Network Error",
	"Minified Redux error",
	"Unexpected end of script",
	"Abort due to cancellation of share",
	"The network connection was lost.",
	"Maximum call stack size exceeded",
	"Possible side-effect in debug-evaluate",
	"UnhandledRejection: Non-Error promise rejection captured with keys: message, status",
	"Non-Error promise rejection captured with keys: message, status",
];

Sentry.init({
	dsn: "https://6455030823e647d7876a21ee6f65e5ae@o151969.ingest.sentry.io/5521493",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.05,
	ignoreErrors: IGNORE_ERRORS,
	release: "0.0.3",
	allowUrls: [
		".fanhubmedia.com",
		"www.superbowlchallenge.com.au",
		"superbowlchallenge.com.br",
		"superbowlchallenge.nfl.com",
		"nflchallenge.ran.de",
		"superbowlchallenge.co.uk",
		"*.nfl.com",
	],
	integrations: [
		// eslint-disable-next-line @typescript-eslint/unbound-method
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		if (event && event.message && IGNORE_ERRORS.includes(event.message)) {
			return null;
		}
		return event;
	},
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
		/api\.boomtrain\.com/i,
	],
});

ReactDOM.render(
	<Provider store={store}>
		<Router basename={"/"}>
			<GlobalPreloader />
			<ComingSoonModal />
			{/*<OffSeasonModal />*/}
			<VideoPlayerModal />
			<HowToPlayModal />
			<RegisterModal />
			<RecoverModal />
			<ConfirmSaveModal />
			<Suspense fallback={<PagePreloader />}>
				<CountriesRedirect>
					<GigyaGate>
						<Translations>
							{PROJECT_NAME !== "" ? (
								<ProjectIndex>
									<Route path={"/"} exact={true} component={Landing} />
									{/* Added for tests. Can be removed before release*/}
									{IS_UAT && <NotAuthOnlyRoute path={"/login_prev"} exact={true} component={Login} />}

									<Route path="/prizes" component={Prizes} />
									<Route exact={true} path="/help" component={Help} />
									<Route path="/help/contact-us" component={ContactUs} />
									<Route path="/help/faqs" component={FAQs} />
									<Route path="/help/game-guidelines" component={GameGuidelines} />
									<Route path="/help/rules" component={GameRules} />
									<Route path="/help/prizes" component={PrizesPool} />
									<Route path="/help/terms" component={Terms} />

									{GameSettingsUtils.IS_ENABLED_SESSION && (
										<Switch>
											<Route path={"/game"} exact={true} component={Brackets} />
											<NotAuthOnlyRoute path={"/backdoor"} exact={true} component={Backdoor} />

											<PrivateRoute exact={true} path="/leagues" component={Leagues} />
											<PrivateRoute
												exact={true}
												path="/leagues/create"
												component={CreateLeague}
											/>
											<PrivateRoute exact={true} path="/leagues/join" component={JoinLeague} />
											<PrivateRoute
												exact={true}
												path="/leagues/join/:code"
												component={JoinLeague}
											/>
											<PrivateRoute
												exact={true}
												path="/league/:comp_id/ladder"
												component={Ladder}
											/>
											<PrivateRoute
												exact={true}
												path="/league/:comp_id/about"
												component={About}
											/>
											<PrivateRoute path="/account/update" component={YourAccount} />
											<PrivateRoute path="/account/nfl-account" component={NFLAccount} />
											<PrivateRoute path="/account/communications" component={Communications} />
											<PrivateRoute
												path="/account/deactivate-account"
												component={DeactivateAccount}
											/>
											<Route path="/view/:userID" component={SharedBrackets} />
											<Route path={"/fans"} exact={true} component={FanPredictions} />
											{/*<Route path="/help/privacy-policy" component={PrivacyPolicy} />*/}
										</Switch>
									)}

									<Route component={NotFound} />
								</ProjectIndex>
							) : null}
						</Translations>
					</GigyaGate>
				</CountriesRedirect>
			</Suspense>
			<GlobalErrors />
		</Router>
	</Provider>,
	document.getElementById("root")
);
