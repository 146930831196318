import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {IconArrowSlim} from "components/Icons/ArrowSlim";
import {ISquad} from "modules/types";
import {fetchTeamStatistics, clearTeamStatistics} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getTeamStatisticsByIds} from "modules/selectors";
import {LeagueRankings} from "./LeagueRankings";
import {TeamsWdl} from "./TeamsWdl";
import theme from "assets/css/theme";
import {get, size} from "lodash";

const TeamStatisticsWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	border-top: 1px solid #e0e0e0;
	font-family: All-ProSans, sans-serif;
`;

const ToggleTeamStatisticsButton = styled.button`
	cursor: pointer;
	background: transparent;
	border: none;
	width: 100%;
	&:focus {
		outline: 0;
	}
`;

const showAnimation = keyframes`
	0% { opacity: .1;}
	100% { opacity: 1; }
`;

const StatisticBlock = styled.div`
	position: absolute;
	width: 100%;
	top: 27px;
	background: #fff;
	text-align: center;
	padding: 10px 0;
	border-radius: 0 0 3px 3px;
	z-index: 5;
	animation-name: ${showAnimation};
	animation-duration: 1s;
`;

const TitleStats = styled.h5`
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	text-transform: uppercase;
	font-family: ${theme.secondary_bold_font};
	margin-bottom: 25px;
`;

interface IProps {
	homeSquad: ISquad;
	awaySquad: ISquad;
	callbackToggleStatistic?: () => void;
}

export const TeamStatistics: React.FC<IProps> = (props) => {
	const dispatch = useDispatch();
	const [isOpenStatistic, changeOpenState] = useState(false);
	const arrow_state = !isOpenStatistic ? "top" : "bottom";
	const {homeSquad, awaySquad, callbackToggleStatistic} = props;
	const toggleStatistic = () => {
		if (callbackToggleStatistic) {
			callbackToggleStatistic();
		}
		changeOpenState(!isOpenStatistic);
	};
	const statistics = useSelector(getTeamStatisticsByIds)(homeSquad?.id, awaySquad?.id);

	useEffect(() => {
		if (isOpenStatistic && !statistics) {
			dispatch(
				fetchTeamStatistics({
					home_id: get(homeSquad, "id", ""),
					away_id: get(awaySquad, "id", ""),
				})
			);
		}
	}, [dispatch, isOpenStatistic, statistics, homeSquad, awaySquad]);

	useEffect(() => {
		return () => {
			dispatch(clearTeamStatistics());
		};
	}, [dispatch]);

	const preventSwipe = (e: React.SyntheticEvent) => e.stopPropagation();

	return (
		<TeamStatisticsWrapper onClick={preventSwipe}>
			<ToggleTeamStatisticsButton onClick={toggleStatistic}>
				<IconArrowSlim width={12} height={8} color={"#000000"} to={arrow_state} />
			</ToggleTeamStatisticsButton>
			{isOpenStatistic && statistics ? (
				size(statistics.league_ranks) && size(statistics.season_record) ? (
					<StatisticBlock>
						<TitleStats>{window.getTranslations("bracket_stats_rankings_title")}</TitleStats>
						<LeagueRankings statistics={statistics} />
						<TeamsWdl statistics={statistics} />
					</StatisticBlock>
				) : (
					<StatisticBlock>
						<TitleStats>{window.getTranslations("stats.warning.text")}</TitleStats>
					</StatisticBlock>
				)
			) : null}
		</TeamStatisticsWrapper>
	);
};
