import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/logo-tall.svg";
import {RegularButton} from "components/Buttons";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {getRePickModalStateSelector} from "modules/selectors";
import {setRePickModalState} from "modules/actions";
import deLogo from "assets/img/de-logo-tall.svg";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
	button {
		max-width: 250px;
	}
`;
const Logo = styled.img`
	width: 100%;
	max-width: 175px;
	margin-bottom: 20px;
`;
const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 21px;
`;
const Text = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 26px;
	margin-bottom: 20px;
`;

export const RePickDescModal: React.FC = () => {
	const dispatch = useDispatch();
	const modalState = useSelector(getRePickModalStateSelector);
	const closeClick = () => {
		dispatch(setRePickModalState(false));
		localStorage.setItem("rePickModalShown", "1");
	};
	const logoIcon = process.env.REACT_APP_NAME === "nfl-de" ? deLogo : logo;
	return modalState ? (
		<Modal onClose={closeClick} isCloseable={true} isCloseOutside={true} width="55%">
			<Wrapper>
				<Logo src={logoIcon} alt={"NFL logo"} />
				<Title>{window.getTranslations("modal_header_repick")}</Title>
				<Text>{window.getTranslations("modal_body_repick")}</Text>
				<RegularButton onClick={closeClick}>{window.getTranslations("modal_button_repick")}</RegularButton>
			</Wrapper>
		</Modal>
	) : null;
};
