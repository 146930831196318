import {createReducer} from "redux-act";
import {IModalsReducer} from "modules/types";
import {
	hideBRSponsorModal,
	showBRSponsorModal,
	showConfirmSaveStoredPredictions,
	hideConfirmSaveStoredPredictions,
} from "modules/actions";

const initialState: IModalsReducer = {
	isOpenBRSponsorModal: false,
	isOverrideModal: false,
};

export const modals = createReducer({}, initialState)
	.on(showBRSponsorModal, (state) => ({
		...state,
		isOpenBRSponsorModal: true,
	}))
	.on(hideBRSponsorModal, (state) => ({
		...state,
		isOpenBRSponsorModal: false,
	}))
	.on(showConfirmSaveStoredPredictions, (state) => ({
		...state,
		isOverrideModal: true,
	}))
	.on(hideConfirmSaveStoredPredictions, (state) => ({
		...state,
		isOverrideModal: false,
	}));
