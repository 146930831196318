import {createReducer, Reducer} from "redux-act";
import {IBracketConfig} from "modules/types/brackets";
import {BRACKETS_CONFIG} from "modules/constants";
import {cloneDeep} from "lodash";
import {
	setSelectedBracketIDAction,
	updateBracketConfig,
	updateBracketsSavedStateAction,
} from "modules/actions/brackets";

const initialState: IBracketConfig = cloneDeep(BRACKETS_CONFIG);
export const brackets: Reducer<IBracketConfig> = createReducer({}, initialState);
brackets.on(updateBracketConfig, (state, payload) => ({
	...state,
	rounds: payload,
}));
brackets.on(updateBracketsSavedStateAction, (state, payload) => ({
	...state,
	isSaved: payload,
}));
brackets.on(setSelectedBracketIDAction, (state, payload) => ({
	...state,
	selectedBracketID: payload,
}));
