import {errorsGlobalError, fetchSharingImgUrlSuccess, IFetchSharingImgUrl, ISendIncrement} from "modules/actions";
import {Api} from "modules/utils/Api";
import {ApiError} from "modules/utils/Api/ApiError";
import {call, put} from "redux-saga/effects";
import {ISagaAction} from "modules/types";

export const fetchSharingImgUrlSaga = function* ({payload}: ISagaAction<IFetchSharingImgUrl>) {
	try {
		const result = yield call(Api.Share.imgUrl, payload);
		ApiError.CHECK(result);
		const {image} = result.result;

		yield put(fetchSharingImgUrlSuccess(image || ""));
	} catch (e) {
		console.log(e);
		yield put(errorsGlobalError(e));
	}
};

export const sendIncrementSaga = function* ({payload}: ISagaAction<ISendIncrement>) {
	try {
		const result = yield call(Api.Share.increment, payload);
		ApiError.CHECK(result);
	} catch (e) {
		console.log(e);
		yield put(errorsGlobalError(e));
	}
};
