import * as process from "process";
import {
	IS_SECRET_ENV,
	IS_PRE_SEASON,
	IS_OFF_SEASON,
	IS_OPEN_PRE_SEASON_REGISTRATION,
	CONFIG_COUNTRY,
} from "modules/constants";

const ENV = process.env.REACT_APP_ENVIRONMENT || "";
const APP_NAME = process.env.REACT_APP_NAME || "";

export abstract class GameSettingsUtils {
	private static _key = `nfl-brackets-secret-${ENV}-${APP_NAME}`;
	static ENABLED = IS_SECRET_ENV && !sessionStorage.getItem(GameSettingsUtils._key);

	static get ROOT_GAME_ROUTE() {
		return GameSettingsUtils.IS_LANDING_ENABLED ? "/" : "/game";
	}

	static PASS() {
		sessionStorage.setItem(GameSettingsUtils._key, "true");
	}

	static get IS_ENABLED_SESSION() {
		// return !GameSettingsUtils.ENABLED || (!IS_PRE_SEASON && !IS_OFF_SEASON);
		if (GameSettingsUtils.IS_OPEN_PRE_SEASON_REGISTRATION) {
			return true;
		}

		if (IS_SECRET_ENV) {
			return !GameSettingsUtils.ENABLED;
		}

		return !IS_PRE_SEASON && !IS_OFF_SEASON;
	}

	static get IS_LANDING_ENABLED() {
		return IS_OFF_SEASON || IS_PRE_SEASON;
	}

	static get IS_OPEN_PRE_SEASON_REGISTRATION() {
		if (!IS_PRE_SEASON) {
			return true;
		}
		return !GameSettingsUtils.ENABLED && IS_OPEN_PRE_SEASON_REGISTRATION && !IS_OFF_SEASON;
	}

	static get IS_BRACKET_BY_LADDER_ENABLED() {
		return false;
	}

	static get linkToPTTP() {
		const linksByCountry: Record<string, string> = {
			au: "https://pathtotheplayoffs.australia.nfl.com/",
			br: "https://pathtotheplayoffs.brazil.nfl.com/",
			ca: "https://pathtotheplayoffs.canada.nfl.com/",
			fr: "https://pathtotheplayoffs.france.nfl.com/",
			gb: "https://pathtotheplayoffs.uk.nfl.com/",
			mx: "https://pathtotheplayoffs.nfl.com/",
			us: "https://pathtotheplayoffs.nfl.com/",
			de: "https://pathtotheplayoffs.sport.de/",
		};

		return linksByCountry[CONFIG_COUNTRY] || linksByCountry.gb;
	}
}
