import {createReducer} from "redux-act";
import {IUserReducer} from "modules/types";
import {
	authedAction,
	backdoorAction,
	cleanUpAuth,
	gigyaLoginAction,
	logoutAction,
	setGigyaDataAction,
	setIsNeedToRegisterAction,
} from "modules/actions/auth";
import {User} from "modules/utils/User";

const defaultState: IUserReducer = {
	get isAuthenticated() {
		return User.IS_AUTHENTICATED();
	},
	get user() {
		return User.GET();
	},
	isNeedToRegister: false,
	isLoading: false,
	gigyaData: {
		email: "",
		gigya_id: "",
		gigya_signature: "",
		gigya_signature_timestamp: "",
		favorite_team: "",
		first_name: "",
		last_name: "",
		postcode: "",
		birthday: "",
	},
};

export const user = createReducer<IUserReducer>({}, defaultState);
user.on(backdoorAction, (state) => ({
	...state,
	isLoading: true,
}));
user.on(authedAction, (state, payload) => ({
	...state,
	isAuthenticated: true,
	isLoading: false,
	user: payload,
}));
user.on(gigyaLoginAction, (state) => ({
	...state,
	isLoading: true,
}));
user.on(setIsNeedToRegisterAction, (state, payload) => ({
	...state,
	isNeedToRegister: payload,
	isLoading: false,
}));
user.on(setGigyaDataAction, (state, payload) => ({
	...state,
	gigyaData: payload,
}));
user.on(logoutAction, () => defaultState);
user.on(cleanUpAuth, (state) => ({
	...defaultState,
	gigyaData: state.gigyaData,
}));
