import React from "react";
import {Modal} from "components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getHowToPlyModalStateSelector} from "modules/selectors";
import {setHowToPlayModalStateAction} from "modules/actions";
import styled from "styled-components";
import {get} from "lodash";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 240px;
	video {
		width: 101%;
		height: 100%;
	}
`;

export const HowToPlayModal: React.FC = () => {
	const dispatch = useDispatch();
	const modalState = useSelector(getHowToPlyModalStateSelector);
	const closeClick = () => {
		dispatch(setHowToPlayModalStateAction(false));
	};
	const syndicateCountry = get(process.env, "REACT_APP_SYNDICATE_COUNTRY", "CA").toLowerCase();
	const videoUrl = `${String(process.env.REACT_APP_VIDEO_URL)}media/video/NFL_how_to_play_${
		syndicateCountry === "us" ? "mx" : syndicateCountry
	}.mp4`;
	return modalState ? (
		<Modal onClose={closeClick} isCloseable={true} isCloseOutside={true} width="55%">
			<Wrapper>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
				<video controls={true} preload="true" autoPlay={true}>
					<source src={videoUrl} type="video/mp4" />
				</video>
			</Wrapper>
		</Modal>
	) : null;
};
