import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {get, find} from "lodash";
import {WILD_CARD_ROUND_ID} from "modules/constants";
import {getRounds} from "modules/selectors/rounds";
import {GameSettingsUtils} from "modules/utils/secret";

export const getGlobalStore = ({global}: IStore) => global;
export const getTranslationStore = ({translations}: IStore) => translations;
export const getGlobalLoaderStateSelector = createSelector(getGlobalStore, ({isGlobalPreloader}) => isGlobalPreloader);
export const getIsRoundsFetchedSelector = createSelector(getGlobalStore, ({isRoundsFetched}) => isRoundsFetched);
export const getHighlightSelector = createSelector(getGlobalStore, ({highLightVideo}) => highLightVideo);
export const getRePickModalStateSelector = createSelector(getGlobalStore, ({showRePickModal}) => showRePickModal);
export const getHowToPlyModalStateSelector = createSelector(
	getGlobalStore,
	({showHowToPlayModal}) => showHowToPlayModal
);

export const isBracketByLadderEnabled = createSelector(
	[getIsRoundsFetchedSelector, getRounds],
	(isRoundsFetched, rounds) => {
		if (!GameSettingsUtils.IS_BRACKET_BY_LADDER_ENABLED) {
			return false;
		}

		const firstRound = find(rounds, {id: WILD_CARD_ROUND_ID});
		const matches = get(firstRound, "matches", []);
		return isRoundsFetched && rounds.length && !matches.length;
	}
);

export const getErrorsState = (state: IStore) => state.error;

export const isComingSoonSelector = createSelector(
	[getIsRoundsFetchedSelector, getRounds],
	(isRoundsFetched, rounds) => {
		const firstRound = find(rounds, {id: WILD_CARD_ROUND_ID});
		const matches = get(firstRound, "matches", []);
		return isRoundsFetched && rounds.length && !matches.length;
	}
);
