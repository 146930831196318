import {IUser, IUserPredictor} from "modules/types";

export const DEFAULT_USER_PREDICTOR: IUserPredictor = {
	birthday: "",
	country: "",
	email_notifications: 0,
	lang: "",
	phone_code: "",
	phone_number: "",
	postcode: "",
	state: "",
	team_name: "",
	team_supported: 0,
	news: 0,
	weekly_results: 0,
	weekly_reminders: 0,
};

export const DEFAULT_USER: IUser = {
	active: 1,
	email: "",
	facebook_id: "",
	first_name: "",
	id: 0,
	last_name: "",
	played: "",
	predictor: DEFAULT_USER_PREDICTOR,
	recovered: 0,
	showed_landscape_popup: 0,
	username: "",
	welcome_popup: 0,
};

export const REGISTRATION_FORM_STATE = {
	team_name: "",
	terms: 0,
	email_notifications: 1,
	client_notifications: 0,
};

export const REGISTRATION_FORM_ERRORS = {
	team_name: "",
	terms: "",
};
