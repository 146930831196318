import React, {useEffect, useState} from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import theme from "assets/css/theme";
import shareIcon from "assets/img/icons/Share-grey.svg";
import facebook from "assets/img/icons/Facebook.svg";
import twitter from "assets/img/icons/Twitter.svg";
import upload from "assets/img/icons/upload.svg";
import {partial, get} from "lodash";
import {getFileToShareImg, share, createConnextraScriptTag} from "modules/utils";
import {ShareType} from "modules/constants/enums";
import {useI18n} from "modules/hooks";
import {useDispatch, useSelector} from "react-redux";
import {getPredictionsStore} from "modules/selectors/predictions";
import {getSquadById, getUserDataSelector} from "modules/selectors";
import {sendIncrementQuery} from "modules/actions";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
	button {
		max-width: 250px;
	}
`;
const Icon = styled.img`
	width: 100%;
	max-width: 30px;
	margin-bottom: 20px;
`;
const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 21px;
`;
const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
const ShareButton = styled.button`
	background: transparent;
	border: none;
	margin: 0 5px;
	cursor: pointer;
`;

const UploadShareButton = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	cursor: pointer;
	border: 1px solid #000000;
	border-radius: 50%;
	height: 42px;
	width: 42px;
`;

interface IProps {
	openOutsideState: boolean;
}
export const ShareBracketsModal: React.FC<IProps> = ({openOutsideState}) => {
	const dispatch = useDispatch();
	const predictions = useSelector(getPredictionsStore);
	const user = useSelector(getUserDataSelector);
	const finalPrediction = predictions[13];
	const predictionSquadID = get(finalPrediction, `squad_id`, "");
	const squad = useSelector(getSquadById)(predictionSquadID);
	const fullName = get(squad, "full_name", "");
	const shareMessage = useI18n("bracket_share_twitter_message", [{team_name: fullName}, {shareURL: ""}]);
	const [openState, changeOpenState] = useState(false);

	useEffect(() => {
		if (openOutsideState) {
			changeOpenState(true);
		}
	}, [openOutsideState]);

	if (!squad) {
		return null;
	}

	const closeClick = () => {
		changeOpenState(false);
	};
	const shareClick = (sc: ShareType) => {
		dispatch(
			sendIncrementQuery({
				type: "predictor",
				social: sc,
			})
		);
		share({
			sc,
			section: "predictor",
			message: shareMessage,
			user_id: user.id,
		});
		closeClick();
	};

	const handleUploadShare = () => {
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_shareclick_download?AccountID=${user.id}`
		);

		dispatch(
			sendIncrementQuery({
				type: "predictor",
				social: "bracket_generate",
			})
		);
	};

	const shareFileUrl = getFileToShareImg(user.id);

	return openState ? (
		<Modal onClose={closeClick} isCloseable={true} width="55%">
			<Wrapper>
				<Icon src={shareIcon} alt={"Share Icon"} />
				<Title>{window.getTranslations("bracket_alert_share_header")}</Title>
				<ButtonsWrapper>
					<ShareButton onClick={partial(shareClick, ShareType.FB)}>
						<img src={facebook} alt="facebook icon" />
					</ShareButton>
					<ShareButton onClick={partial(shareClick, ShareType.TW)}>
						<img src={twitter} alt="twitter icon" />
					</ShareButton>
					<UploadShareButton href={shareFileUrl} target={"_blank"} onClick={handleUploadShare}>
						<img src={upload} alt="upload icon" />
					</UploadShareButton>
				</ButtonsWrapper>
			</Wrapper>
		</Modal>
	) : null;
};
