import React, {ReactNode} from "react";
import styled from "styled-components";
import theme from "assets/css/theme";

interface IWrapperWithSidebar {
	readonly offset_up?: boolean;
	readonly padding?: string;
	readonly position: string;
	readonly section_background?: string;
	readonly section_padding?: string;
}

const WrapperWithSidebar = styled.div`
	display: flex;
	flex-direction: ${({position}: IWrapperWithSidebar) =>
		position === "right" ? "row" : "row-reverse"};
	width: 100%;
	max-width: ${theme.content_width};
	margin: 50px auto;
	box-sizing: border-box;
	padding: 0 20px;
	position: relative;
	align-items: flex-start;
	top: ${({offset_up}: IWrapperWithSidebar) => (offset_up ? "-185px" : 0)};

	@media screen and (max-width: 820px) {
		width: 100%;
		margin: 0;
		padding: 0;
		flex-direction: column;
	}

	section {
		flex: 69%;
		width: 69%;
		${({position}: IWrapperWithSidebar) =>
			position === "right" ? "margin-right: 2.5%;" : "margin-left: 2.5%;"};
		background-color: ${({section_background}: IWrapperWithSidebar) =>
			section_background ? section_background : "transparent"};
		padding: ${({section_padding}: IWrapperWithSidebar) =>
			section_padding ? section_padding : 0};
		border-radius: 4px;
		box-shadow: 0 1px 2px 1px #ececec;
		@media screen and (max-width: 820px) {
			flex: 1;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		}
	}
	aside {
		flex: 28.5%;
		@media screen and (max-width: 820px) {
			flex: 1;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		}
	}
`;

interface IWrapperSkeleton {
	readonly children: React.ReactNode;
	readonly empty_sidebar?: boolean;
	readonly offset_up?: boolean;
	readonly sidebar_components?: ReactNode[];
	readonly padding?: string;
	readonly position?: string;
	readonly section_background?: string;
	readonly section_padding?: string;
	readonly hidden_sidebar_on_mobile?: boolean;
}

export const ContentWithSidebar = ({
	children,
	empty_sidebar,
	sidebar_components = [],
	offset_up,
	padding,
	position = "right",
	section_background,
	section_padding,
	hidden_sidebar_on_mobile,
}: IWrapperSkeleton) => (
	<WrapperWithSidebar
		padding={padding}
		offset_up={offset_up}
		position={position}
		section_background={section_background}
		section_padding={section_padding}>
		<section>{children}</section>
		<aside className={hidden_sidebar_on_mobile ? "hidden-mobile" : ""}>
			{empty_sidebar ? null : sidebar_components.map((component) => component)}
		</aside>
	</WrapperWithSidebar>
);
