import * as React from "react";
import {
	FooterWrapper,
	FooterMenuWrapper,
	TermLink,
	Copywriting,
	FooterImgDiv,
	FooterImg,
	FooterImgWrapDiv,
	FooterTermsDiv,
} from "./FooterStyle";
import nflLogo from "assets/img/mini-logo.png";
import {useI18n} from "modules/hooks";
import styled from "styled-components";
import hubLogo from "assets/img/genius.svg";
import {Exist} from "components/Exist";
import {useSelector} from "react-redux";
import {isMXSelector} from "modules/selectors";

const FunHubLogo = styled.img`
	margin-top: 30px;
	opacity: 0.5;
`;

interface IProps {
	SponsorLogo?: React.FC;
	hideNflLogo?: boolean;
}

export const Footer: React.FC<IProps> = ({SponsorLogo, hideNflLogo = false}) => {
	const footerTermsText = useI18n("global_footer_terms");
	const footerDescText = useI18n("global_footer_desc");
	const isMX = useSelector(isMXSelector);

	return (
		<React.Fragment>
			<FooterWrapper isMX={isMX}>
				<FooterMenuWrapper>
					<FooterImgWrapDiv>
						<Exist when={!hideNflLogo}>
							<FooterImgDiv>
								<FooterImg src={nflLogo} alt={"NFL logo"} />
							</FooterImgDiv>
						</Exist>

						{SponsorLogo ? (
							<FooterImgDiv>
								{" "}
								<SponsorLogo />{" "}
							</FooterImgDiv>
						) : null}
					</FooterImgWrapDiv>

					<FooterTermsDiv>
						<TermLink to="/help/rules">{footerTermsText}</TermLink>
					</FooterTermsDiv>

					<Copywriting>{footerDescText}</Copywriting>
				</FooterMenuWrapper>
				<a href={"https://fanhubmedia.com/"} target={"_blank"} rel={"noreferrer"}>
					<FunHubLogo src={hubLogo} alt={"Fanhub logo"} />
				</a>
			</FooterWrapper>
		</React.Fragment>
	);
};
