import React, {CSSProperties} from "react";
import styled, {css} from "styled-components";
import {media} from "assets/css/media";
import closeIcon from "assets/img/icons/clear-grey.svg";

interface IModalWrapper {
	width?: string;
	height?: string;
}

const ModalWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 91;
	display: flex;
	justify-content: center;
	align-items: center;
	overscroll-behavior: none;
`;

const getModalInnerStyled = (params: IModalWrapper) => css`
	max-width: ${params.width || "85%"};
	max-height: ${params.height || "85%"};
	width: 100%;
	overflow: hidden;
	overflow-y: auto;

	@media screen and (max-width: ${media.mobile}) {
		width: 95%;
		max-width: 95%;
		max-height: 98%;
	}
`;

const ModalInner = styled.div`
	background: #fff;
	position: relative;
	border-radius: 6px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overscroll-behavior: none;
	${getModalInnerStyled};
`;

const CloseButton = styled.button`
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	background: none;
	border-radius: 0;
	border: none;
	width: 30px;
	z-index: 2;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		flex: 0 0 auto;
	}
`;

interface IProps {
	children?: React.ReactNode;
	isCloseable?: boolean;
	onClose: (args: React.SyntheticEvent) => null | void;
	width?: string;
	height?: string;
	style?: CSSProperties;
	isCloseOutside?: boolean;
}

export const Modal: React.FC<IProps> = ({
	children = null,
	isCloseable = true,
	onClose,
	isCloseOutside = false,
	...rest
}: IProps) => {
	const onOutsideClose = (e: React.SyntheticEvent) => {
		if (isCloseOutside) {
			onClose(e);
		}
	};
	const stopPropagationPlaceholder = (e: React.SyntheticEvent) => {
		e.stopPropagation();
	};
	return (
		<ModalWrapper onClick={onOutsideClose} className={"modal-wrapper"}>
			<ModalInner {...rest} onClick={stopPropagationPlaceholder}>
				{isCloseable && (
					<CloseButton onClick={onClose}>
						<img src={closeIcon} alt="closr icon" />
					</CloseButton>
				)}
				{children}
			</ModalInner>
		</ModalWrapper>
	);
};
